{
    "title": "page.findus.title",
    "components": [
        {
            "type": "tabs",
            "tabs": [
                {
                    "tab": "page.findus.llanishen.tab",
                    "children": [
                        {
                            "type": "row",
                            "id": "",
                            "children": [
                                {
                                    "type": "heading",
                                    "value": "page.findus.llanishen.title",
                                    "key": "7wy3D/RrQWCaXrFpodVZsw=="
                                },
                                {
                                    "type": "column",
                                    "width": 3,
                                    "children": [
                                        {
                                            "type": "text",
                                            "value": "67 Thornhill Road",
                                            "key": "C98kERs6T/+qJfqEG3q/Mw=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "Llanishen",
                                            "key": "3oYs8GK+RhWVHuDexA0d/A=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "CF14 6PE",
                                            "key": "w/zaZTlYQCCR2DgxIvtMFw=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "page.findus.llanishen.phone",
                                            "key": "BhMH06haSI2l/68d0XuGVA=="
                                        }
                                    ],
                                    "key": "Nw92WjU3SnCwQlIf3GQIGg=="
                                },
                                {
                                    "type": "column",
                                    "width": 3,
                                    "children": [
                                        {
                                            "type": "text",
                                            "value": "page.findus.llanishen.freephone",
                                            "key": "HPHbL1agR2qU3isLB4QIpg=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "page.findus.llanishen.fax",
                                            "key": "gXbgyNHqT9eIdcKxQVoy9Q=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "page.findus.llanishen.weekdayopeningtimes",
                                            "key": "gJFGLJJ/R0G7q2a+0mpkig=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "page.findus.llanishen.saturdayopeningtimes",
                                            "key": "nvoaXNyRRRGBnoTQtwHruA=="
                                        }
                                    ],
                                    "key": "FUU9ZUm9T/Gs2KSTphcOaQ=="
                                },
                                {
                                    "type": "column",
                                    "width": 6,
                                    "children": [
                                        {
                                            "type": "text",
                                            "value": "page.findus.llanishen.alternative",
                                            "markdown": true,
                                            "key": "vf1uqOd7QIKIlZJ78DelmQ=="
                                        }
                                    ],
                                    "key": "j0WjsgWHTbSVXhwdCt4WsQ=="
                                }
                            ],
                            "key": "zYhO/g/GS2aa0gqzADuDrg=="
                        },
                        {
                            "type": "row",
                            "id": "",
                            "children": [
                                {
                                    "type": "column",
                                    "width": 6,
                                    "children": [
                                        {
                                            "type": "map",
                                            "id": "lisvane_map",
                                            "coordinates": "!1m14!1m8!1m3!1d1241.2334139350812!2d-3.200132!3d51.522997!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9fe89ad7f039c685!2sInsync+Health+Llanishen!5e0!3m2!1sen!2suk!4v1487075661139",
                                            "key": "5bymtDWYRKGORzwnjv8few=="
                                        }
                                    ],
                                    "key": "0w1FayXuS4+TWfNC4VOR6A=="
                                },
                                {
                                    "type": "column",
                                    "width": 6,
                                    "children": [
                                        {
                                            "type": "checklist",
                                            "id": "lisvane_services",
                                            "items": [
                                                {
                                                    "value": "page.findus.llanishen.services.s1",
                                                    "key": "LOOwN1JpRyaAJDT5GSToog=="
                                                },
                                                {
                                                    "value": "page.findus.llanishen.services.s2",
                                                    "key": "BU4U4wHDQ+mMDyHBKgnOwA=="
                                                },
                                                {
                                                    "value": "page.findus.llanishen.services.s3",
                                                    "key": "rFw62sQuSeC8Q31WKYLVvw=="
                                                },
                                                {
                                                    "value": "page.findus.llanishen.services.s4",
                                                    "key": "h209hO+fQeW7+J290X6wFA=="
                                                },
                                                {
                                                    "value": "page.findus.llanishen.services.s5",
                                                    "key": "+4Iju/DOQxmL0aS8gsXYow=="
                                                },
                                                {
                                                    "value": "page.findus.llanishen.services.s6",
                                                    "key": "NLA30oiVQLW3JCD8wPgVZg=="
                                                },
                                                {
                                                    "value": "page.findus.llanishen.services.s7",
                                                    "key": "QHFU0UTzTBqx0UkpnOb8xg=="
                                                },
                                                {
                                                    "value": "page.findus.llanishen.services.s8",
                                                    "key": "uLngtJ4YQciWg3WKJT1zag=="
                                                },
                                                {
                                                    "value": "page.findus.llanishen.services.s9",
                                                    "key": "t0vbI6s1QVKxJOVA1HxF5Q=="
                                                },
                                                {
                                                    "value": "page.findus.llanishen.services.s10",
                                                    "key": "WpcHNsWLQJO2ok6ACbfLfA=="
                                                },
                                                {
                                                    "value": "page.findus.llanishen.services.s11",
                                                    "key": "izEAQWSpSD+Hfqh2hpqfKQ=="
                                                },
                                                {
                                                    "value": "page.findus.llanishen.services.s12",
                                                    "key": "FDnktv/iTcyFRDEpi0Voyg=="
                                                },
                                                {
                                                    "value": "page.findus.llanishen.services.s13",
                                                    "key": "6TF2mb+ETbyLbLRqBmNnBA=="
                                                },
                                                {
                                                    "value": "page.findus.llanishen.services.s14",
                                                    "key": "SkCNVYHBQVqTixm9ofVYgg=="
                                                },
                                                {
                                                    "value": "page.findus.llanishen.services.s15",
                                                    "key": "uazReHR7StWFH8vrjLKnLg=="
                                                },
                                                {
                                                    "value": "page.findus.llanishen.services.s16",
                                                    "key": "WpznnxRxR0qR0smOZaOUmg=="
                                                },
                                                {
                                                    "value": "page.findus.llanishen.services.s17",
                                                    "key": "6IwWLMFKQjKCSadonGcfcA=="
                                                }
                                            ],
                                            "key": "CSoc9LymRgeKoHQzR74z3w=="
                                        }
                                    ],
                                    "key": "JUymjObiSpOQLQ1FGaHBBw=="
                                }
                            ],
                            "key": "BKgnBufvQ6C1iSiV/NPPvw=="
                        },
                        {
                            "type": "row",
                            "id": "",
                            "children": [
                                {
                                    "type": "column",
                                    "width": 6,
                                    "children": [
                                        {
                                            "type": "image",
                                            "image": "NHS_Wales_logo.jpg",
                                            "class": "nhs-wales-logo",
                                            "key": "rq6jiv0mQ66Epn/o6JdYBA=="
                                        }
                                    ],
                                    "key": "jYavyOf0QcGhE0HIcMhTkw=="
                                }
                            ],
                            "key": "w0hKRAMFTlK5r6tKMWpP9A=="
                        }
                    ],
                    "key": "DaShaavrRfq03kMX31Dsvw=="
                },
                {
                    "tab": "page.findus.lisvane.tab",
                    "children": [
                        {
                            "type": "row",
                            "id": "",
                            "children": [
                                {
                                    "type": "text",
                                    "value": "page.findus.lisvane.title",
                                    "bold": true,
                                    "key": "+UkNMuj1T1CTEvui+WfQ2Q=="
                                },
                                {
                                    "type": "column",
                                    "width": 3,
                                    "children": [
                                        {
                                            "type": "text",
                                            "value": "2 Heol Y Delyn",
                                            "key": "KgzPpltzT3+gdGodH7MjBw=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "Lisvane",
                                            "key": "x3E1z9HoR6aKDcAJCI2fvA=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "CF14 0SQ",
                                            "key": "ufgd1k7oSq6qt1Vzc1ZxfQ=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "page.findus.lisvane.phone",
                                            "key": "5lvqDQfaSCq8xbI+PZ9z7A=="
                                        }
                                    ],
                                    "key": "pHrvC+ccQMK+tK4EOFzNiA=="
                                },
                                {
                                    "type": "column",
                                    "width": 3,
                                    "children": [
                                        {
                                            "type": "text",
                                            "value": "page.findus.lisvane.freephone",
                                            "key": "ES8qB/xmQGS2twFuahrnCA=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "page.findus.lisvane.fax",
                                            "key": "7yDyaJsXSGCn9GZxM9IKXQ=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "page.findus.lisvane.prescriptioncollection",
                                            "key": "bGdrMYUPQh+moq/++f5Maw=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "page.findus.lisvane.openingtimes",
                                            "key": "EqT3jwpWTE6TSOVvRR/IqQ=="
                                        }
                                    ],
                                    "key": "UyIzotwbRaCNuJDhimVlQw=="
                                },
                                {
                                    "type": "column",
                                    "width": 6,
                                    "children": [
                                        {
                                            "type": "text",
                                            "value": "page.findus.lisvane.alternative",
                                            "markdown": true,
                                            "key": "TIibFo6oQ6Kn7qGSm8p1XQ=="
                                        }
                                    ],
                                    "key": "KpZfpZtfQ76Pgh0SL059Kg=="
                                }
                            ],
                            "key": "x4ue+Wh9SM+1w0GuSYjQQQ=="
                        },
                        {
                            "type": "row",
                            "id": "",
                            "children": [
                                {
                                    "type": "column",
                                    "width": 6,
                                    "children": [
                                        {
                                            "type": "map",
                                            "id": "lisvane_map",
                                            "coordinates": "!1m18!1m12!1m3!1d19854.588911685398!2d-3.2045043673135236!3d51.53479494692317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486e1c3c1c76610f%3A0x34fcb6d0b0244d62!2sInsync+Pharmacy+Lisvane!5e0!3m2!1sen!2suk!4v1487076155442",
                                            "key": "6prJxrGPQaGAmQ5/hu3BNg=="
                                        }
                                    ],
                                    "key": "e+/F+qZwT2K2kORI8kwj4w=="
                                },
                                {
                                    "type": "column",
                                    "width": 6,
                                    "children": [
                                        {
                                            "type": "checklist",
                                            "id": "lisvane_services",
                                            "items": [
                                                {
                                                    "value": "page.findus.lisvane.services.s1",
                                                    "key": "EGiQ1ln7TYuEGKUSes511A=="
                                                },
                                                {
                                                    "value": "page.findus.lisvane.services.s2",
                                                    "key": "CnZzCcWeQyGLLAXgYmpffw=="
                                                },
                                                {
                                                    "value": "page.findus.lisvane.services.s3",
                                                    "key": "sySa5mNdR+uzk46ZcZW02w=="
                                                },
                                                {
                                                    "value": "page.findus.lisvane.services.s4",
                                                    "key": "GRYCB6HRS9yQdFePl/nvZA=="
                                                },
                                                {
                                                    "value": "page.findus.lisvane.services.s5",
                                                    "key": "efRcZ8LRQs+2Iisluh7t/g=="
                                                },
                                                {
                                                    "value": "page.findus.lisvane.services.s6",
                                                    "key": "YebZ5wi8QtWBa+9czcR9mg=="
                                                },
                                                {
                                                    "value": "page.findus.lisvane.services.s7",
                                                    "key": "wAUFoprhSQesqzIt23RCiA=="
                                                }
                                            ],
                                            "key": "JZO+jY1uSYGaO+OAPmeXAw=="
                                        }
                                    ],
                                    "key": "/PcMmmxmRcy8gO5ZivTg1g=="
                                }
                            ],
                            "key": "0CxRe/amSU2FNvD6l+oDaA=="
                        }
                    ],
                    "key": "f9GSpseQS4W7G9q4dfE0dQ=="
                }
            ],
            "key": "n/5Ykf8IRZqXjQrOc2pMbA=="
        }
    ],
    "key": "8NrP4IlISNuKYLOQd4o8ig=="
}
{
    "title": "page.earwaxremoval.title",
    "components": [
        {
            "type": "panel",
            "title": "page.earwaxremoval.access.title",
            "children": [
                {
                    "type": "checklist",
                    "id": "earwaxremoval_service",
                    "items": [
                        {
                            "value": "page.earwaxremoval.access.c1",
                            "key": "UB2m1+jFRSKzIBbdZFd70g=="
                        },
                        {
                            "value": "page.earwaxremoval.access.c2",
                            "key": "xjg2DdZfSQ6AaIqf5IFJnQ=="
                        },
                        {
                            "value": "page.earwaxremoval.access.c3",
                            "key": "uh3nMdqWRwqfzfsWYlz7mw=="
                        },
                        {
                            "value": "page.earwaxremoval.access.c4",
                            "key": "SZfYXy1NTFqR3AyGTeIzRQ=="
                        },
                        {
                            "value": "page.earwaxremoval.access.c5",
                            "key": "mhxYYhLoSdK5eLJfafVaow=="
                        },
                        {
                            "value": "page.earwaxremoval.access.c6",
                            "key": "CdgTVJ8ASqqvqXXKmdZUcQ=="
                        },
                        {
                            "value": "page.earwaxremoval.access.c7",
                            "key": "4J/fHf6QQwKqCskp1o7Twg=="
                        }
                    ],
                    "key": "LVGni8HoSr2A1UhPX1xzTg=="
                },
                {
                    "type": "row",
                    "class": "earwaximage-row-top-margin",
                    "children": [
                        {
                            "type": "column",
                            "width": 3,
                            "children": [
                                {
                                    "type": "text",
                                    "value": "page.earwaxremoval.access.before",
                                    "key": "xV2gMr/OSTq03lC/rnXHSA=="
                                },
                                {
                                    "type": "image",
                                    "image": "before_ear.png",
                                    "key": "Amhq1pLrQTqZs3sqiR2fvg=="
                                }
                            ],
                            "key": "pI/RS7P8SK2GPi17FKzNtg=="
                        },
                        {
                            "type": "column",
                            "width": 3,
                            "children": [
                                {
                                    "type": "text",
                                    "value": "page.earwaxremoval.access.after",
                                    "key": "I9gsaGgpTJKqT2mGuAB7Zg=="
                                },
                                {
                                    "type": "image",
                                    "image": "after_ear.png",
                                    "key": "QfCzu3ytQiSUiB0ywhs7QQ=="
                                }
                            ],
                            "key": "7QuXs6mdS2WngfmKS7FP/Q=="
                        }
                    ],
                    "key": "Q1FE3xZYT5GERAQiyNVNnA=="
                }
            ],
            "key": "nxv3u9P/SIOkNpAezeRGRg=="
        },
        {
            "type": "panel",
            "title": "page.earwaxremoval.fees.title",
            "children": [
                {
                    "type": "text",
                    "value": "page.earwaxremoval.fees.p1",
                    "key": "SCsP6aYpKk6HV9mgUWg8Rg==",
                    "underline": true
                },
                {
                    "type": "checklist",
                    "id": "earwaxremoval_service",
                    "items": [
                        {
                            "value": "page.earwaxremoval.fees.p1c1",
                            "key": "cYGVOJdtHESgTZEaes8uUw=="
                        },
                        {
                            "value": "page.earwaxremoval.fees.p1c2",
                            "key": "vo67aiNT9k6IOpurtsKU8w=="
                        }
                    ],
                    "key": "1vqzlw0rcEmiEPeHDbDOzg=="
                },
                {
                    "type": "text",
                    "value": "page.earwaxremoval.fees.p2",
                    "key": "1sHSzebb2EqJeOZgbm2dIg=="
                }
            ],
            "key": "YICzjAcDgkWOMAjWUfDUtw=="
        }
    ],
    "key": "7VW+YLe1RsGJj9k/3wudHg=="
}
import { createGlobalState } from 'react-hooks-global-state';

const { setGlobalState, useGlobalState } = createGlobalState({
    loaded: false,
    globalData: {},
    pageCache: []
});

export const setGlobalData = (globalData) => {
    setGlobalState('globalData', globalData);
};

export const setLoaded = (loaded) => {
    setGlobalState('loaded', loaded);
}

export const setPageCache = (pageCache) => {
    setGlobalState('pageCache', pageCache);
}

export { useGlobalState };
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const Text = (props) => {
    const { t, i18n } = useTranslation();
    let clazz = props.bold ? `text-bold ${props.class}` : props.class;
    clazz = props.underline ? `text-underline ${clazz}` : clazz; 
    return (props.markdown ?
        <ReactMarkdown className={clazz}>{t(props.value)}</ReactMarkdown> :
        <p className={clazz}>{t(props.value)}</p>
    );
}

export default Text;
import React, { useReducer, useEffect } from 'react';
import { Button, Form } from 'semantic-ui-react'
import { Loader } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks'

const initialState = {
    fields: {},
    sending: false,
    success: false
};

const reducer = (state, action) => {
    if (action.type == 'reset') {
        return initialState;
    }

    const result = { ...state };
    result[action.type] = action.value;
    return result;
}

const InputForm = (props) => {
    const { t, i18n } = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { fields, sending, success } = state;

    const addField = (field) => {
        fields[field.tag] = "";
        dispatch({ type: 'fields', value: fields });
    }

    const handleChange = (e, { name, value }) => {
        fields[name] = value;
        dispatch({ type: 'fields', value: fields });
    }

    const [{ data: postData, loading: postLoading, error: postError }, executePost] = useAxios({
        url: 'https://dev-remedi-api-fa-pr.azurewebsites.net/api/contact/send',
        method: 'POST' 
    }, { manual: true })

    const handleSubmit = () => {
        dispatch({ type: 'sending', value: true });

        executePost({
            data: {
              ...fields,
              updatedAt: new Date().toISOString()
            }
        }).then((data) => {
             dispatch({ type: 'success', value: true });
        });

        dispatch({ type: 'sending', value: false });
    }
    
    const messageSuccess = () => {
        return (<div className="row" ref={(row) => { successRow = row; }}>
            <div className="col-6">
                <div className="panel">
                    <h1>{t(props.success.title)}</h1>
                    <p>{t(props.success.description)}</p>
                    <a href="/">{t(props.success.return_home)}</a>
                </div>
            </div>
        </div>)
    }

    useEffect(() => {
        props.fields.map((field, index) => {
            addField(field)
        })
    }, [])

    const getField = (field) => {
        switch(field.type) {
            case 'text': 
            case 'email': {
                return (<Form.Input
                    key={field.key}
                    required
                    label={t(field.title)}
                    type={field.type}
                    onChange={handleChange}
                    name={field.tag}
                    value={fields[field.tag]} />)
            }
            case 'text_area': {
                return (<Form.TextArea
                    key={field.key}
                    required
                    label={t(field.title)}
                    onChange={handleChange}
                    name={field.tag}
                    value={fields[field.tag]} />)
            }
        }
    }

    return ((success && messageSuccess()) || <Form onSubmit={handleSubmit}>
        {props.fields.map((field, index) => {
            return getField(field)
        })}
        <Button type='submit' className='btn submit-btn' disabled={sending}>{t(props.submit)}</Button>
        {sending && <Loader style={{ marginLeft: 10, marginBottom: 10 }} active inline />}
    </Form>);
}

export default InputForm;
{
    "title": "page.ols.title",
    "fetch": {
        "api": "remedi-api",
        "data": "ols",
        "key": "N7sVxcEYToGXTyu/FUFE2Q=="
    },
    "components": [
        {
            "type": "tabs",
            "tabs": [
                {
                    "tab": "page.ols.doctors.tab",
                    "children": [
                        {
                            "type": "heading",
                            "value": "page.ols.doctors.title",
                            "key": "Ml22YsM6RceGZfs3HYE1wQ=="
                        },
                        {
                            "type": "table",
                            "virtual_columns": [
                                {
                                    "title": "name_address",
                                    "children": [
                                        {
                                            "type": "text",
                                            "value": "name",
                                            "bold": true,
                                            "key": "EDORUAi5R92X7yakg4JkyA=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "address",
                                            "key": "Y/hkxGPSTdGvhrCvMd2TXw=="
                                        }
                                    ],
                                    "key": "Wfinqj75SoyVFgj203dyGw=="
                                },
                                {
                                    "title": "phone",
                                    "children": [
                                        {
                                            "type": "text",
                                            "value": "phone",
                                            "key": "1itHjxqLQOy2XrLPATAe0w=="
                                        }
                                    ],
                                    "key": "bwkKL5qzSMKZXQCzf7UmbQ=="
                                }
                            ],
                            "source": "api",
                            "data": "doctors",
                            "key": "wqknCUW5QHC2A+GoYEUojg=="
                        }
                    ],
                    "key": "/RJRiRUZTZKCyyBAEg0g+Q=="
                },
                {
                    "tab": "page.ols.dentists.tab",
                    "children": [
                        {
                            "type": "heading",
                            "value": "page.ols.dentists.title",
                            "key": "0JdoDUQkRd6L34ioOGh9QQ=="
                        },
                        {
                            "type": "table",
                            "virtual_columns": [
                                {
                                    "title": "name_address",
                                    "children": [
                                        {
                                            "type": "text",
                                            "value": "name",
                                            "bold": true,
                                            "key": "SCqOJGDASyKi1R8fSYOCxA=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "address",
                                            "key": "3OgV4r6CTLWxnr7w5wx+ag=="
                                        }
                                    ],
                                    "key": "UqtG8X30Rh6GHyylE8Zdmw=="
                                },
                                {
                                    "title": "phone",
                                    "children": [
                                        {
                                            "type": "text",
                                            "value": "phone",
                                            "key": "UhQxYAaJToyAVBTuZaleTw=="
                                        }
                                    ],
                                    "key": "XZHE9ADvRQmSXaRs4h9VmA=="
                                }
                            ],
                            "source": "api",
                            "data": "dentists",
                            "key": "zY31dti1SJWFOA5YgPswVw=="
                        }
                    ],
                    "key": "Ox3AEXhJTYOJCkEx109rgA=="
                },
                {
                    "tab": "page.ols.optoms.tab",
                    "children": [
                        {
                            "type": "heading",
                            "value": "page.ols.optoms.title",
                            "key": "LhEC/v+LTTCj+eUK0VQsTw=="
                        },
                        {
                            "type": "table",
                            "virtual_columns": [
                                {
                                    "title": "name_address",
                                    "children": [
                                        {
                                            "type": "text",
                                            "value": "name",
                                            "bold": true,
                                            "key": "0l31V7XcSt+wshQktwo+Dw=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "address",
                                            "key": "ZrUCg2jsQ7WRRKkfHRY73w=="
                                        }
                                    ],
                                    "key": "6YoQq464QPWMvYf3+Tal4g=="
                                },
                                {
                                    "title": "phone",
                                    "children": [
                                        {
                                            "type": "text",
                                            "value": "phone",
                                            "key": "ualkNmPqTiC0AvogdDcb+A=="
                                        }
                                    ],
                                    "key": "jOhxeOR2TvuoFu1izZ/Ewg=="
                                }
                            ],
                            "source": "api",
                            "data": "optometrists",
                            "key": "YSzK0RuSSSOIN+j4bwDPAQ=="
                        }
                    ],
                    "key": "OO92PSrkQ3iCgylnwbVa5Q=="
                },
                {
                    "tab": "page.ols.aande.tab",
                    "children": [
                        {
                            "type": "heading",
                            "value": "page.ols.aande.title",
                            "key": "iTiaNA9WRwGZF4+Eqe2nxg=="
                        },
                        {
                            "type": "row",
                            "id": "",
                            "children": [
                                {
                                    "type": "column",
                                    "width": 3,
                                    "children": [
                                        {
                                            "type": "text",
                                            "value": "University Hospital of Wales",
                                            "key": "OXiF8RJaQSCIm41fBqrMWg=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "Heath Park",
                                            "key": "QV4FNQFZSjG1hhOyNtPQzw=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "Cardiff",
                                            "key": "uuLXNhgAR7quvkhMSqInIQ=="
                                        },
                                        {
                                            "type": "text",
                                            "value": "CF14 4XW",
                                            "key": "inPezeiMTLKzl5bCpDKhTA=="
                                        }
                                    ],
                                    "key": "Bx4JxGiXS8C2nCu5cG0n3g=="
                                }
                            ],
                            "key": "ITKBkMSoQdeHMHED8sE/aw=="
                        },
                        {
                            "type": "row",
                            "id": "",
                            "children": [
                                {
                                    "type": "column",
                                    "width": 6,
                                    "children": [
                                        {
                                            "type": "map",
                                            "id": "aande_map",
                                            "coordinates": "!1m14!1m8!1m3!1d9933.521245444568!2d-3.190222!3d51.5062384!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdab5d0df6eac692e!2sUniversity+Hospital+of+Wales+Emergency+Unit!5e0!3m2!1sen!2suk!4v1493572393426",
                                            "key": "jCFxyZfHTpORQL50mkJh3A=="
                                        }
                                    ],
                                    "key": "ETuc4MjsQDSIxTmPjgsIcw=="
                                }
                            ],
                            "key": "qrEOCdmATqKVtWHiaOWQWQ=="
                        }
                    ],
                    "key": "LMqNzfUpREOp4MBD771R4A=="
                },
                {
                    "tab": "page.ols.outofhours.tab",
                    "children": [
                        {
                            "type": "heading",
                            "value": "page.ols.outofhours.whoarewe.title",
                            "key": "2xNaYEenTVWCCSub6jwcZA=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.whoarewe.p1",
                            "key": "IDloIqwtRfiAfJhitlitPg=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.whoarewe.p2",
                            "key": "Z568pllIQkKBs7QpIk4Hsg=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.whoarewe.p3",
                            "key": "TxqSVkQGQQ2nC0wEgy2+Tw=="
                        },
                        {
                            "type": "heading",
                            "value": "page.ols.outofhours.ooh.title",
                            "key": "/FyDtTTMRaiLZ/uxuq52Ag=="
                        },
                        {
                            "type": "checklist",
                            "items": [
                                {
                                    "value": "page.ols.outofhours.ooh.c1",
                                    "key": "mHA63FTfQYOIbVox5PE8VQ=="
                                },
                                {
                                    "value": "page.ols.outofhours.ooh.c2",
                                    "key": "RvLqkERSSYeZ487OzK+NrQ=="
                                },
                                {
                                    "value": "page.ols.outofhours.ooh.c3",
                                    "key": "d0zS5dd0RfeSgc2zlFKOFg=="
                                }
                            ],
                            "key": "JCJ3ht1ESV+LyCmH4Bmgsw=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.ooh.p1",
                            "key": "989lxAC4Sdyeu6zElku1Xw=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.ooh.p2",
                            "key": "E0BQSwTdSaCugYwiRwkfaQ=="
                        },
                        {
                            "type": "heading",
                            "value": "page.ols.outofhours.wherearewe.title",
                            "key": "OsnNNZB3TCCd2t6m2GSdYQ=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.wherearewe.p1",
                            "key": "Q7UG8wKdSLC3qGcsm6LR4g=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.wherearewe.p2",
                            "key": "mN/epQtOSVGODCWVYZ/kng=="
                        },
                        {
                            "type": "heading",
                            "value": "page.ols.outofhours.faqs.title",
                            "key": "Qr/Oz2kESjaWEagxMOv5NQ=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.faqs.p1",
                            "bold": true,
                            "key": "IQjvBhLLSEesHtXBJwbjGA=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.faqs.p2",
                            "key": "h3FIjcQmTE+lkO7dYfWjfA=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.faqs.p3",
                            "bold": true,
                            "key": "TH3L7j9PSg2LJ7wcMGWmNQ=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.faqs.p4",
                            "key": "fvg6erVQSsWzChPytTYQ4Q=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.faqs.p5",
                            "key": "zE4XXOywRXyKt7O4bKCm5g=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.faqs.p6",
                            "bold": true,
                            "key": "PJ1j8CvqR6qzxQuKZ4rzig=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.faqs.p7",
                            "key": "OPF00gS/SOeoSTrn1Foedg=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.faqs.p8",
                            "bold": true,
                            "key": "1TZ9INnuRd+IyPq55XixbQ=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.faqs.p9",
                            "key": "YUUbZMnuQmasfvxouZf7fw=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.faqs.p10",
                            "key": "5X/+mM3eTsOuNYSUvDU2+w=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.faqs.p11",
                            "bold": true,
                            "key": "4Sjt9ooOSOO5oYzrOZKg1A=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.faqs.p12",
                            "key": "Dy/KxEFoTxyXAiSB8wwLTg=="
                        },
                        {
                            "type": "text",
                            "value": "page.ols.outofhours.faqs.p13",
                            "bold": true,
                            "underline": true,
                            "key": "0MvrdQ5RQ9a4DxtQz9GRiw=="
                        }
                    ],
                    "key": "jhnTkAg2SjqbC3qfRC8N1g=="
                }
            ],
            "key": "EC9yQ4hsSt6LnxrUiY4W7w=="
        }
    ],
    "key": "Oqjko93HQxeKVa1SzwZdNg=="
}
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown, { ReactMarkdownProps } from 'react-markdown';
import nextKey from './KeyGenerator';

const CheckList = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <ul className="tick-list">
            {props.items.map((item, index) => {
                return (<li key={item.key}><span><ReactMarkdown
                children={t(item.value)}
                disallowedElements={['p']}
                unwrapDisallowed={true}
                components={{
                    paragraph: props => <span {...props}/>
                }}
            /></span></li>)
            })}
        </ul>
    );
}

export default CheckList;
{
    "title": "page.repeats.title",
    "components": [
      {
        "key": "DKOLeSbWoUKcOdMDDNfMmQ==",
        "type": "panel",
        "title": "page.repeats.repeats.title",
        "children": [
          {
            "key": "dGVzdC1rZXktMQ==",
            "type": "text",
            "value": "page.repeats.repeats.p1"
          },
          {
            "key": "dGVzdC1rZXktMg==",
            "type": "text",
            "value": "page.repeats.repeats.p2"
          },
          {
            "key": "dGVzdC1rZXktMw==",
            "type": "text",
            "value": "page.repeats.repeats.p3"
          },
          {
            "key": "dGVzdC1rZXktNA==",
            "type": "imageLink",
            "image": "google-play-badge.png",
            "url": "https://play.google.com/store/apps/details?id=app.remedi.repeats",
            "height": 58
          },
          {
            "key": "dGVzdC1rZXktNQ==",
            "type": "imageLink",
            "image": "app-store-badge.svg",
            "url": "https://apps.apple.com/gb/app/id1502236015",
            "style": {
              "paddingBottom": "9px"
            }
          }
        ]
      }
    ]
  }
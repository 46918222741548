import React from 'react';
import { useTranslation } from 'react-i18next';

const Breadcrumb = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <ul className="breadcrumb">
            <li><a href="/">{t('layout.nav.home')}</a></li>
            <li>{props.title}</li>
        </ul>
    )
}

export default Breadcrumb;
{
    "title": "page.travel.title",
    "components": [
        {
            "type": "panel",
            "title": "page.travel.title",
            "children": [
                {
                    "type": "text",
                    "value": "page.travel.p1",
                    "key": "keMr9/EeRSaxOQIQJdKUjw=="
                },
                {
                    "type": "checklist",
                    "id": "travel_services",
                    "items": [
                        {
                            "value": "page.travel.p1c1",
                            "key": "ujWmVV6aTH2WRCg+MK81qA=="
                        },
                        {
                            "value": "page.travel.p1c2",
                            "key": "6mE2l+CpSQaaefBU7rkBbw=="
                        },
                        {
                            "value": "page.travel.p1c3",
                            "key": "2ZkLCo14TlioNtJA+uB5tQ=="
                        }
                    ],
                    "key": "Ab5k8/poTSWDSEnPEKoWXw=="
                },
                {
                    "type": "text",
                    "value": "page.travel.p2",
                    "bold": true,
                    "key": "MC8Xxv0oQiy5At3yCB/UIw=="
                },
                {
                    "type": "text",
                    "value": "page.travel.p3",
                    "key": "61Wjt2i5QHiiBC2xTKvCgw=="
                },
                {
                    "type": "text",
                    "value": "page.travel.p4",
                    "key": "PuwCo11fSBiHpAqY5fhrxg=="
                },
                {
                    "type": "text",
                    "value": "page.travel.p5",
                    "key": "mtD8bI5ISzGli7hwFzQUoQ=="
                },
                {
                    "type": "text",
                    "value": "page.travel.p6",
                    "key": "An+V0F5kSTWoQ8gEQNm8Bg=="
                },
                {
                    "type": "text",
                    "value": "page.travel.p7",
                    "key": "LJ7Xq8F0T0ea79t1irzBSg=="
                },
                {
                    "type": "text",
                    "value": "page.travel.p8",
                    "bold": true,
                    "key": "OqXtb6kmQRGtJUAq3b0lXA=="
                },
                {
                    "type": "text",
                    "value": "page.travel.p11",
                    "key": "LJ7Xq8F0T0ea79t1irzBSg=="
                },
                {
                    "type": "button",
                    "key": "KzdeUj5G8UmI05yQ1hIDlQ==",
                    "id": "",
                    "title": "page.travel.p20",
                    "url": "/TravelRiskAssessment"
                },
                {
                    "type": "text",
                    "value": "page.travel.p21",
                    "key": "o0t2DXa63Uel/U6cz8cCSw=="
                },
                {
                    "type": "checklist",
                    "id": "travel_consultations",
                    "items": [
                        {
                            "value": "page.travel.p9",
                            "key": "W5D9ne6TTJWgArUEdGgQag=="
                        },
                        {
                            "value": "page.travel.p10",
                            "key": "KJtNMMqwTmOffWp3duVXvQ=="
                        }
                    ],
                    "key": "gkgYNPxsRbWg/OmwVT7wdg=="
                },
                {
                    "type": "text",
                    "value": "page.travel.p12",
                    "bold": true,
                    "key": "pINwy9U5RtuGhSdqCgmD4A=="
                },
                {
                    "type": "text",
                    "value": "page.travel.p13",
                    "key": "DlUlg1JRSZCvhgOD4wUu2Q=="
                },
                {
                    "type": "table",
                    "columns": [
                        "page.travel.p14",
                        "page.travel.p15",
                        "page.travel.p16"
                    ],
                    "source": "file",
                    "data": "travel",
                    "footer": "page.travel.p17",
                    "headingStyle": {
                        "fontWeight": "bold",
                        "textAlign": "center",
                        "paddingTop": 30,
                        "fontSize": "1.5em",
                        "key": "yRDxjpw9TNyf3ysIeOjFrw=="
                    },
                    "cellStyle": {
                        "paddingTop": 15,
                        "paddingLeft": 15,
                        "key": "tiMfu+EgQhWUGFZFkzEkxg=="
                    },
                    "cellStyleNoLeftPadding": {
                        "paddingTop": 15,
                        "key": "V+3/imKDT4uTkvi0EEOAwA=="
                    },
                    "key": "HNzczy15SkWwqow6rwZf2g=="
                },
                {
                    "type": "link",
                    "value": "page.travel.p18",
                    "icon": "instragram.png",
                    "url": "https://www.instagram.com/insynctravelclinic/",
                    "bold": true,
                    "key": "OUgDmP1ySdCyB4FNHMVZrw=="
                }
            ],
            "key": "xbuc5uRBROyGSIacUXMNxw=="
        }
    ],
    "key": "oDU0Y369QOuFVByfBwZaWA=="
}
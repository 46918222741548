import * as React from 'react';
import { useTranslation } from 'react-i18next';

const Video = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="row">
            <div className="col-6">
                <div className="videoWrapper">
                    <iframe width="560" height="349" src={props.url} frameBorder="0" allowFullScreen></iframe>
                </div>
            </div>
        </div>
    )
}

export default Video;
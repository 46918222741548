{
    "title": "page.about.title",
    "breadcrumb": false,
    "components": [
        {
            "type": "carousel",
            "items": [
                {
                    "id": "banner_1",
                    "description": "Click the language selector to swap to Welsh",
                    "original": "welsh_language_banner.png",
                    "thumbnail": "welsh_language_banner.png",
                    "key": "qtPntUEp8ESgrhyuT/x9Pg=="
                },
                {
                    "id": "banner_2",
                    "description": "page.home.banners.repeatprescriptionbanner.description",
                    "original": "RepeatsBanner.png",
                    "thumbnail": "RepeatsBanner.png",
                    "learn_more_url": "/Repeats",
                    "key": "tv2kHsPdTpyhdLBTtKJ84w=="
                },
                {
                    "id": "banner_3",
                    "description": "page.home.banners.commonailmentsbanner.description",
                    "original": "minor_ailments_bannerv2.jpg",
                    "thumbnail": "minor_ailments_bannerv2.jpg",
                    "learn_more_url": "/CommonAilments",
                    "key": "axEno1AYTEGTJlgqqG+GkQ=="
                },
                {
                    "id": "banner_4",
                    "description": "page.home.banners.welcomebanner.description",
                    "original": "PharmacyBanner.jpg",
                    "thumbnail": "PharmacyBanner.jpg",
                    "learn_more_url": "/About",
                    "key": "sPPVyxZPSXSN94rDRhCgBQ=="
                },
                {
                    "id": "banner_5",
                    "description": "page.home.banners.privateearwaxbanner.description",
                    "original": "ear_wax_removal_banner.png",
                    "thumbnail": "ear_wax_removal_banner.png",
                    "originalWidth": 100,
                    "learn_more_url": "/EarWaxRemoval",
                    "key": "qtKg+zMtT7aJxdSOePjbDA=="
                },
                {
                    "id": "banner_6",
                    "description": "page.home.banners.flu.description",
                    "original": "FluBanner.jpg",
                    "thumbnail": "FluBanner.jpg",
                    "originalWidth": 100,
                    "learn_more_url": "/InfluenzaVaccinations",
                    "key": "0XtAku0RnUWkWXuSXX6NmA=="
                }
            ],
            "key": "ECZo9OXfT8C5tfBuW/rg8g=="
        },
        {
            "type": "row",
            "id": "",
            "children": [
                {
                    "type": "column",
                    "width": 12,
                    "children": [
                        {
                            "type": "heading",
                            "value": "page.home.services.title",
                            "key": "n95WMZkaTS+IViXsdwKebg=="
                        }
                    ],
                    "key": "Ra/H7kdbRRip/OrdjJPhqA=="
                }
            ],
            "key": "BOA6NXJuS8CkBEtEV4EuYg=="
        },
        {
            "type": "linkgrid",
            "id": "",
            "children": [
                {
                    "url": "/Ordering",
                    "description": "page.home.services.ordering.description",
                    "title": "page.home.services.ordering.title",
                    "class": "ordering-panel",
                    "key": "fO8R0s0+RKu4ECFqqO/76A=="
                },
                {
                    "url": "/WeightLoss",
                    "description": "page.home.services.weightloss.description",
                    "title": "page.home.services.weightloss.title",
                    "class": "weightloss-panel",
                    "key": "RCC0EqIWT724BJ5sozKJNA=="
                },
                {
                    "url": "/Travel",
                    "description": "page.home.services.travelclinic.description",
                    "title": "page.home.services.travelclinic.title",
                    "class": "travel-panel",
                    "key": "HAqSLEvTTO6Pz3D8ZCpaqw=="
                },
                {
                    "url": "/EarWaxRemoval",
                    "description": "page.home.services.earwaxremoval.description",
                    "title": "page.home.services.earwaxremoval.title",
                    "class": "ear-wax-removal-panel",
                    "key": "Sx2vQ7utTu6IGdPgg2OvHg=="
                },
                {
                    "url": "/CommonAilments",
                    "description": "page.home.services.commonailments.description",
                    "title": "page.home.services.commonailments.title",
                    "class": "common-ailments-panel",
                    "key": "OO7FwoqsTtiriajnJOxDSg=="
                },
                {
                    "url": "/InfluenzaVaccinations",
                    "description": "page.home.services.vaccines.description",
                    "title": "page.home.services.vaccines.title",
                    "class": "iv-panel",
                    "key": "d1fSwA6oRg+vQr5qhTInwQ=="
                },
                {
                    "url": "/PharmacySales",
                    "description": "page.home.services.sales.description",
                    "title": "page.home.services.sales.title",
                    "class": "pharmacy-sales-panel",
                    "key": "6fIc8M1uScKiryoeqLD9Ew=="
                },
                {
                    "url": "/OtherLocalServices",
                    "description": "page.home.services.ols.description",
                    "title": "page.home.services.ols.title",
                    "class": "other-local-services-panel",
                    "key": "InlGmahVRI2W1Nc/W3jcwA=="
                }
            ],
            "key": "8IgwnXzeTii9gqIldrD3VQ=="
        },
        {
            "type": "panel",
            "title": "page.home.repeats.title",
            "class": "repeat-prescription-panel",
            "children": [
                {
                    "type": "row",
                    "children": [
                        {
                            "type": "column",
                            "width": 6,
                            "children": [
                                {
                                    "type": "text",
                                    "value": "page.home.repeats.description",
                                    "key": "0X7NDoZyTA6032ijtur1qQ=="
                                }
                            ],
                            "key": "ri2rymd4Q42Pqm8zHLoWtw=="
                        },
                        {
                            "type": "column",
                            "width": 6,
                            "class": "order-col",
                            "children": [
                                {
                                    "type": "button",
                                    "id": "",
                                    "title": "page.home.repeats.moreinformation",
                                    "url": "/Ordering",
                                    "key": "SZqXcqoTSXSa0H2TnOeowQ=="
                                }
                            ],
                            "key": "d/+BNtJpS/mfCOk2W0bJOQ=="
                        }
                    ],
                    "key": "uGbZPvSyRsOQH4L0oXqFDg=="
                }
            ],
            "key": "pQofW8cRRO+Lx8QXatdsZg=="
        },
        {
            "type": "panel",
            "title": "page.home.registration.title",
            "children": [
                {
                    "type": "row",
                    "children": [
                        {
                            "type": "column",
                            "width": 12,
                            "children": [
                                {
                                    "type": "text",
                                    "value": "page.home.registration.description",
                                    "key": "0ZpZUHK9Ty+9dvFp0PNkEA=="
                                }
                            ],
                            "key": "w7zJlTosRMWv0PO9BjwErg=="
                        },
                        {
                            "type": "column",
                            "width": 12,
                            "class": "centre padding-10",
                            "children": [
                                {
                                    "type": "button",
                                    "id": "",
                                    "title": "page.home.registration.checkregistration",
                                    "external": true,
                                    "url": "https://www.pharmacyregulation.org/registers/pharmacy/nameortown/insync",
                                    "key": "95AtmYYYQPWSD81HGGU33A=="
                                }
                            ],
                            "key": "xOG82R4YT1aTI6A8xHKyeg=="
                        }
                    ],
                    "key": "SxLa6fK2S/C9oPZ5ZwX03A=="
                }
            ],
            "key": "+OJmP5hRQfWYJYTBjdXQdg=="
        },
        {
            "type": "row",
            "id": "",
            "children": [
                {
                    "type": "column",
                    "width": 12,
                    "children": [
                        {
                            "type": "heading",
                            "value": "page.home.findus.title",
                            "key": "TwFQ3+v2SomE2tfd6UIpLw=="
                        }
                    ],
                    "key": "mtNs8htORIOfE5usDmrPTw=="
                }
            ],
            "key": "2y2AIn71QZmiZv7tALgEAw=="
        },
        {
            "type": "row",
            "id": "",
            "children": [
                {
                    "type": "column",
                    "width": 6,
                    "children": [
                        {
                            "type": "panel",
                            "title": "page.home.findus.lanishen",
                            "disable_columns": true,
                            "children": [
                                {
                                    "type": "map",
                                    "coordinates": "!1m14!1m8!1m3!1d1241.2334139350812!2d-3.200132!3d51.522997!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9fe89ad7f039c685!2sInsync+Pharmacy+Llanishen!5e0!3m2!1sen!2suk!4v1487075661139",
                                    "key": "qJmD/eDWQ1yWUrz21lmHDQ=="
                                }
                            ],
                            "key": "pK5ztkmfR0KwDC+g+CQ25Q=="
                        }
                    ],
                    "key": "YwAjIRcFTpaFgnY+/UxjFw=="
                },
                {
                    "type": "column",
                    "width": 6,
                    "children": [
                        {
                            "type": "panel",
                            "title": "page.home.findus.lisvane",
                            "disable_columns": true,
                            "children": [
                                {
                                    "type": "map",
                                    "coordinates": "!1m18!1m12!1m3!1d19854.588911685398!2d-3.2045043673135236!3d51.53479494692317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486e1c3c1c76610f%3A0x34fcb6d0b0244d62!2sInsync+Health+Lisvane!5e0!3m2!1sen!2suk!4v1487076155442",
                                    "key": "FfRCZaMlQOCawvol7rLDOw=="
                                }
                            ],
                            "key": "LIFRGpohQWqWCPgxa+KAeg=="
                        }
                    ],
                    "key": "MT3ON0MZS56N7sy6+wKk+w=="
                }
            ],
            "key": "bZP+UEqDSseGV9CIJQqJyg=="
        },
        {
            "type": "row",
            "id": "",
            "children": [
                {
                    "type": "column",
                    "width": 12,
                    "children": [
                        {
                            "type": "heading",
                            "value": "page.home.keepuptodate.title",
                            "key": "sFxJ5OJ6R1Clge+noPpm4w=="
                        }
                    ],
                    "key": "XUD401CHQQWKae1pWriN9w=="
                }
            ],
            "key": "ZQHJHld8TlKYxCq1ZCWMig=="
        },
        {
            "type": "row",
            "id": "",
            "children": [
                {
                    "type": "column",
                    "width": 6,
                    "children": [
                        {
                            "type": "panel",
                            "title": "page.home.keepuptodate.twitter",
                            "disable_columns": true,
                            "children": [
                                {
                                    "type": "twitter",
                                    "handle": "InsyncPharmacy",
                                    "key": "0530zbQHRuKURsw16exneA=="
                                }
                            ],
                            "key": "H1nexQHiSHOtbr1ql9jGQg=="
                        }
                    ],
                    "key": "5qgZlTTKQXqDwadyRZC0Wg=="
                },
                {
                    "type": "column",
                    "width": 6,
                    "children": [
                        {
                            "type": "panel",
                            "title": "page.home.keepuptodate.facebook",
                            "disable_columns": true,
                            "children": [
                                {
                                    "type": "facebook",
                                    "title": "page.home.keepuptodate.facebook_link",
                                    "url": "http://www.facebook.com/insyncpharmacy/",
                                    "key": "llsa8xnpTpyhvkLrYGdBhQ=="
                                }
                            ],
                            "key": "b8EGWI1jS/qsbEMSXYeogQ=="
                        }
                    ],
                    "key": "H0UuKxskTLuAKgJA0B7F5g=="
                }
            ],
            "key": "u7wfn7P1QlubGZBIcpQsOg=="
        }
    ],
    "key": "1dq85i0RSq29RhhK+OeXvg=="
}
import * as React from 'react';
import nextKey from '../components/KeyGenerator';
import Panel from '../components/Panel';
import CheckList from '../components/CheckList';
import Button from '../components/Button';
import ImageLink from '../components/ImageLink';
import Text from '../components/Text';
import nextKey from '../components/KeyGenerator';
import Video from '../components/Video';
import Table from '../components/Table';
import Link from '../components/Link';
import Quote from '../components/Quote';
import Column from '../components/Column';
import Image from '../components/Image';
import Row from '../components/Row';
import Tabs from '../components/Tabs';
import Map from './Map';
import Heading from './Heading';
import Carousel from './Carousel';
import LinkGrid from './LinkGrid';
import Twitter from './Twitter';
import Facebook from './Facebook';
import InputForm from './InputForm';

const ComponentFactory = (props) => {
    switch (props.type) {
        case 'panel': {
            return (<Panel {...props}>
                {props.children.map((child, index) => {
                    return ComponentFactory(child)
                })}
            </Panel>)
        }
        case 'checklist': {
            return (<CheckList {...props} />)
        }
        case 'button': {
            return (<Button {...props} />)
        }
        case 'imageLink': {
            return (<ImageLink {...props} />)
        }
        case 'heading': {
            return (<Heading {...props} />)
        }
        case 'text': {
            return (<Text {...props} />)
        }
        case 'video': {
            return (<Video {...props} />)
        }
        case 'table': {
            return (<Table {...props} />)
        }
        case 'link': {
            return (<Link {...props} />)
        }
        case 'quote': {
            return (<Quote {...props} />)
        }
        case 'row': {
            return (<Row {...props}>
                {props.children.map((child, index) => {
                    return ComponentFactory(child)
                })}
            </Row>)
        }
        case 'column': {
            return (<Column {...props}>
                {props.children.map((child, index) => {
                    return ComponentFactory(child)
                })}
            </Column>)
        }
        case 'image': {
            return (<Image {...props} />)
        }
        case 'tabs': {
            return (<Tabs {...props} />)
        }
        case 'map': {
            return (<Map {...props} />)
        }
        case 'carousel': {
            return (<Carousel {...props} />)
        }
        case 'linkgrid': {
            return (<LinkGrid {...props} />)
        }
        case 'twitter': {
            return (<Twitter {...props} />)
        }
        case 'facebook': {
            return (<Facebook {...props} />)
        }
        case 'form': {
            return (<InputForm {...props} />)
        }
    }
}

export default ComponentFactory;
{
    "title": "page.cookies.cookies.title",
    "components": [
      {
        "key": "NP6ZUeLD9EaLB2MW2GKnHw==",
        "type": "panel",
        "title": "page.cookies.cookies.title",
        "children": [
          {
            "type": "text",
            "key": "dGVzdC1rZXktMQ==",
            "value": "page.cookies.cookies.p1"
          }
        ]
      },
      {
        "key": "gjG2BXX+bEuSE31nA5KCBA==",
        "type": "panel",
        "title": "page.cookies.whatarecookies.title",
        "children": [
          {
            "type": "text",
            "key": "dGVzdC1rZXktMg==",
            "value": "page.cookies.whatarecookies.p1"
          }
        ]
      },
      {
        "key": "3deBPI2+MESqHgkTOVDAIw==",
        "type": "panel",
        "title": "page.cookies.howdoweusecookies.title",
        "children": [
          {
            "type": "text",
            "key": "dGVzdC1rZXktMw==",
            "value": "page.cookies.howdoweusecookies.p1"
          },
          {
            "key": "XcPVY2cjB0uVn/YrY+B7QA==",
            "type": "checklist",
            "id": "ordering_phone",
            "items": [
              {
                "key": "dGVzdC1rZXktNA==",
                "value": "page.cookies.howdoweusecookies.p1c1"
              },
              {
                "key": "dGVzdC1rZXktNQ==",
                "value": "page.cookies.howdoweusecookies.p1c2"
              },
              {
                "key": "dGVzdC1rZXktNg==",
                "value": "page.cookies.howdoweusecookies.p1c3"
              }
            ]
          },
          {
            "type": "text",
            "key": "dGVzdC1rZXktNw==",
            "value": "page.cookies.howdoweusecookies.p2"
          },
          {
            "type": "text",
            "key": "dGVzdC1rZXktOA==",
            "value": "page.cookies.howdoweusecookies.p3"
          },
          {
            "type": "text",
            "key": "dGVzdC1rZXktOQ==",
            "value": "page.cookies.howdoweusecookies.p4"
          }
        ]
      },
      {
        "key": "02IoAtMkMkm9x62UgRLopw==",
        "type": "panel",
        "title": "page.cookies.howtocontrolcookies.title",
        "children": [
          {
            "type": "text",
            "key": "dGVzdC1rZXktMTA=",
            "value": "page.cookies.howtocontrolcookies.p1"
          }]
      }
    ]
}
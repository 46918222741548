import axios from 'axios';
import { AZURE_HOST_PREFIX } from './api_config';

export default class rest {
    static get = (functionApp, func, successCallback, errorCallback) => {
        axios.get(rest._buildUrl(functionApp, func))
        .then(function (response) {
            successCallback(response);
        })
        .catch(function (error) {
            errorCallback(error);
        });
    }

    static get = (functionApp, func, qs, successCallback, errorCallback) => {
        axios.get(rest._buildUrl(functionApp, func) + '?' + qs)
        .then(function (response) {
            successCallback(response);
        })
        .catch(function (error) {
            errorCallback(error);
        });
    }

    static getAuth = (functionApp, func, successCallback, errorCallback) => {
        let auth = 'Bearer ' + rest._getAccessToken();
        axios.get(rest._buildUrl(functionApp, func), { headers: { Authorization: auth } })
        .then(function (response) {
            successCallback(response);
        })
        .catch(function (error) {
            errorCallback(error);
        });
    }

    static post = (functionApp, func, payload, successCallback, errorCallback) => {
        axios.post(rest._buildUrl(functionApp, func), payload)
        .then(function (response) {
            successCallback(response);
        })
        .catch(function (error) {
            errorCallback(error);
        });
    }

    static postAuth = (functionApp, func, payload, successCallback, errorCallback) => {
        let auth = 'Bearer ' + rest._getAccessToken();
        axios.post(rest._buildUrl(functionApp, func), payload, { headers: { Authorization: auth } })
        .then(function (response) {
            successCallback(response);
        })
        .catch(function (error) {
            errorCallback(error);
        });
    }

    static put = (functionApp, func, payload, successCallback, errorCallback) => {
        axios.put(rest._buildUrl(functionApp, func), payload)
        .then(function (response) {
            successCallback(response);
        })
        .catch(function (error) {
            errorCallback(error);
        });
    }

    static putAuth = (functionApp, func, payload, successCallback, errorCallback) => {
        let auth = 'Bearer ' + rest._getAccessToken();
        axios.put(rest._buildUrl(functionApp, func), payload, { headers: { Authorization: auth } })
        .then(function (response) {
            successCallback(response);
        })
        .catch(function (error) {
            errorCallback(error);
        });
    }

    static _buildUrl(functionApp, func) {
        return 'https://' + AZURE_HOST_PREFIX + '-' + functionApp + '-fa-pr.azurewebsites.net/api/' + func;
        //return 'http://localhost:7071/api/' + func; //for local service testing
    }

    static _getAccessToken() {
        return window.localStorage.getItem('access_token');
    }
}
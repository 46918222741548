{
    "title": "page.privacypolicy.title",
    "components": [
        {
            "type": "panel",
            "title": "page.privacypolicy.title",
            "children": [
                {
                    "type": "text",
                    "value": "page.privacypolicy.p1",
                    "bold": true,
                    "key": "vZpaHmIESp6KLLYo1d6/Wg=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p1.1",
                    "key": "R0LFfJ1dQc6XwIKm/GP7fg=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p1.2",
                    "key": "QdliSYdnSvm4qBP+D3efEw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p1.3",
                    "key": "AiFj7SZrQrqtAF6u8ypMnQ=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p2",
                    "bold": true,
                    "key": "Eimc6B+WTvOpBcZnG+srKg=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p2.1",
                    "key": "Sk/2TdlaSh+QlZ5CXZc7fw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p3",
                    "bold": true,
                    "key": "ophNJIlhSfm8oFruvylJOw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p3.1",
                    "key": "1h0ADMELSyKUZpRinypplw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p3.1a",
                    "key": "SZr3L70eRYuK2G4qfqvEBg=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p3.1b",
                    "key": "xn5mYjsUSWav68+PkI7PgQ=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p3.1c",
                    "key": "FMZ+VcDcQBGQ+eX9TrtX6g=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p3.2",
                    "key": "9VKuHpF0TCyO6jf4jkey2A=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p3.3",
                    "key": "uWHNjTMRTSyNwGfXkUMRQw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p3.4",
                    "key": "9d2w9yHCTE6UEJCgBgGLhA=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p3.5",
                    "key": "QFUzktYSQXCVbhT59B52Yw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p3.6",
                    "key": "JleBry1oTG6+ZrX9FmgFQw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p3.7",
                    "key": "0kE6qRhgQDevs+9FQP21Mw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p3.8",
                    "key": "097AklQyQlWa3y4tzx+mqA=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p4",
                    "bold": true,
                    "key": "yfVF2ORYT825rYjABQj94A=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p4.1",
                    "key": "O4zpVaNQQ+21w7VtvHkKVA=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p4.2",
                    "key": "czonshBvRmSuNryzBDEF5A=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p4.3",
                    "key": "oEX37keaRFSFTmI1HF7l8Q=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p4.4",
                    "key": "kwpm1HSvSt2N9PXbc/AFzA=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p4.4a",
                    "key": "l1izPvz4TVqrTFeKvtA+5A=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p4.4b",
                    "key": "ImNyUslBQu63jS0VQpl5Cg=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p4.5",
                    "key": "WUEo9zd1RTeHMB5MVluDaw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p5",
                    "bold": true,
                    "key": "Ymu9Kg7ZTFu8l9HUewDKsQ=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p5.1",
                    "key": "gYbflPQ+RuK2BJtn/OK1MQ=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p5.2",
                    "key": "VYGe3NP0Q3uOnUJMlQYneA=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p5.3",
                    "key": "ZvpwiZxKSc2wKpUhLdSnHQ=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p5.3a",
                    "key": "Y/kchZttTi2t8LONAu1yzA=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p5.3b",
                    "key": "Qvci0yImSO6VrZXCQ/jjiA=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p5.4",
                    "key": "ta9wrQeFT/6l/QXqihQaTw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p6",
                    "bold": true,
                    "key": "W6AM09t5SJGmYM7e0orpPw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p6.1",
                    "key": "ADvTNPl3QSWuE3wokTUwcw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p6.2",
                    "key": "T1bCsTM0TkWf26WqtcXwyw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p6.3",
                    "key": "vFd+q2meTi6iC9lfPmAcIg=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7",
                    "bold": true,
                    "key": "BxqBX6n2TOeOzi3tv/TQVg=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.1",
                    "key": "fGkqfC1nSCKvXqiXpvz/dw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.2",
                    "key": "Dyrhpv1eTWelyDfi6fxiBQ=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.2a",
                    "key": "R8sABLDaTZClWVVyDFoNdA=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.2b",
                    "key": "ZRKHbkRoRxK15ZLBGSXwLQ=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.2c",
                    "key": "Igb4vFofTtGZI9uVht0DKw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.2d",
                    "key": "lYCAAOXKTh+2qVO/lSuw+g=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.2e",
                    "key": "miqO1GRFTyi6EXicMGWjFg=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.2f",
                    "key": "aNIjEgluQ7GXOQr7aK3ElQ=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.2g",
                    "key": "TdN4f4uETQmBslr2YEdCKw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.2h",
                    "key": "phslLa7VRJOfcXOQ61QDqQ=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.3",
                    "key": "KynYdKtkTZuHX+ZVFaOGvw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.4",
                    "key": "YMQ4OnjlTnOo3vBbk+oqKQ=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.5",
                    "key": "J2Dy1WXrTZ+0TV7zGUPdFQ=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.6",
                    "key": "meCm/4fyTvCJ39ogg4bu7w=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.7",
                    "key": "nnSXYPowT56AaRlG1cJ00g=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.8",
                    "key": "VwY2+PxzTgKQD97LIM/WXA=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.9",
                    "key": "DiNGWvouR2GwryRLz7NObQ=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.10",
                    "key": "8m37QRETREeM4xutW1eRRw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.10a",
                    "key": "RVxMtQ/aQ4i1yjI3INu4Qg=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.10b",
                    "key": "DDoxkKiUQf61X/RrZNuezQ=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.11",
                    "key": "pqt1fzqzSb2c3seDXbMHew=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.12",
                    "key": "WaOT8rIPSe+9Jg0Ni6DT0w=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p7.13",
                    "key": "346LSF99RvWrkGkHyLAZJw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p8",
                    "bold": true,
                    "key": "P6BNVKckRf2VIKQ9nAU/ng=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p8.1",
                    "key": "M3Z4l7mtRwGMI8WaMyV/Eg=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p8.2",
                    "key": "Dm/1alPFRXOb/RH9SUdz7w=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p8.3",
                    "key": "nYIqpnSaT0+gcg41lhfLGQ=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p8.4",
                    "key": "UYtAvpzWQgii9iDEcEntDw=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p8.4a",
                    "key": "94h/U1mGSnOW5hoPhZRPUA=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p8.4b",
                    "key": "zEyUilzBTmKyYMMqE7hYug=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p8.4c",
                    "key": "5vW0LWmZTFKE2au1nF/HyQ=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p9",
                    "bold": true,
                    "key": "eZUpMGDSTAKb130NbUUYJQ=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p9.1",
                    "key": "PPFMYNtQQnuY32aF+O8IxA=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p10",
                    "bold": true,
                    "key": "++QDZZqw0kW+8V+ozkoOpA=="
                },
                {
                    "type": "text",
                    "value": "page.privacypolicy.p10.1",
                    "key": "JYCqlN2opUeZeKBwIiA4uA=="
                }
            ],
            "key": "esap3MUES0WQsR8gr5Zl5A=="
        }
    ],
    "key": "qboPEWgPRLyGf5wqE+zFtQ=="
}
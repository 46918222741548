import * as React from 'react';
import { useTranslation } from 'react-i18next';

const Panel = (props) => {
    const { t, i18n } = useTranslation();
    let clazz = props.class ? `panel ${props.class}` : "panel";
    let columns = props.disable_columns ? "" : "col-12";
    return (
        <div className="row">
            <div className={columns}>
                <div className={clazz}>
                    <div className="row">
                        {props.title && <h1>{t(props.title)}</h1>}
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Panel;
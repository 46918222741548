{
    "title": "page.about.title",
    "components": [{
        "key": "JADtxRwNDEmKHEzwObJlBg==",
        "type": "panel",
        "title": "page.about.thebeginning.title",
        "children": [{
            "key": "Y6Mnvm1FNEm+4k5EuENphw==",
            "type": "column",
            "width": 3,
            "children": [{
                "key": "DYWguQTPnUKSEZ2ClmuqFw==",
                "type": "image",
                "image": "Front.jpg"
            }]
        }, {
            "key": "BetkroGwlU2yyWApIzSpjw==",
            "type": "column",
            "width": 9,
            "children": [{
                    "key": "b0zzRFWqWU2W8QBgESZlow==",
                    "type": "text",
                    "value": "page.about.thebeginning.p1"
                }, {
                    "key": "xG8Pj7GZxECHfbK1C9igcw==",
                    "type": "text",
                    "value": "page.about.thebeginning.p2"
                }, {
                    "key": "Z7sLjcWQU02o/daM6siMKg==",
                    "type": "text",
                    "value": "page.about.thebeginning.p3"
            }]
        }]
    },{
        "key": "htf1jOZ5HUG7mz3b5XWNbA==",
        "type": "panel",
        "title": "page.about.growth.title",
        "children": [{
            "key": "MDjltPLK3k2cNXI8K/kPLA==",
            "type": "column",
            "width": 9,
            "children": [{
                    "key": "zJBBuKl+8UC4G69j09H0Jw==",
                    "type": "text",
                    "value": "page.about.growth.p1"
                }, {
                    "key": "R4YyV1fO+E6L2b2Oq6XNIw==",
                    "type": "text",
                    "value": "page.about.growth.p2"
                }, {
                    "key": "6jNemOwrWE+D3XvjIlS7+Q==",
                    "type": "text",
                    "value": "page.about.growth.p3"
                }, {
                    "key": "ZbQ+l4mz4kap+fo9/7yWFA==",
                    "type": "text",
                    "value": "page.about.growth.p4"
            }]
        }, {
            "key": "J+IFx3LVy06ZWHulIJ4ZYQ==",
            "type": "column",
            "width": 3,
            "children": [{
                "key": "LSitX3/Fs0SbHIjxPkrRiQ==",
                "type": "image",
                "image": "RobAwards.jpg"
            }]
        }]
    },{
        "key": "B04XcyyjrkelIfT9en4TOA==",
        "type": "panel",
        "title": "page.about.partnerships.title",
        "children": [{
            "key": "lzOFFFf+nkWhv6Rd2N7Bww==",
            "type": "column",
            "width": 3,
            "children": [{
                "key": "464rIRQikUKr1NMiPtp1WA==",
                "type": "image",
                "image": "LlanishenHighAwards.png"
            }]
        }, {
            "key": "kxWGp7EL2U29AVQXvv8VPQ==",
            "type": "column",
            "width": 9,
            "children": [{
                    "key": "kCYcrOay70q0GoFbbTrQ/Q==",
                    "type": "text",
                    "value": "page.about.partnerships.p1"
                }, {
                    "key": "D4r1VXUOCEieSdgRhgMgAw==",
                    "type": "text",
                    "value": "page.about.partnerships.p2"
                }, {
                    "key": "QoC0cQRkI0CG9vx260QKaA==",
                    "type": "text",
                    "value": "page.about.partnerships.p3"
            }]
        }]
    }]
}
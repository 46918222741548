import * as React from 'react';
import Column from './Column';
import ObjectLink from './ObjectLink';
import Row from './Row';
import { useTranslation } from 'react-i18next';

const LinkGrid = (props) => {
    const { t, i18n } = useTranslation();

    const getRow = (row, totalRows, remainder) => {
        let totalColumns = (row+1) === totalRows ? remainder != 0 ? remainder : 4 : 4;
        let rowMarkup = [];
        for(let i=1; i<=totalColumns; ++i) {
            let panel = props.children[(row*4)+i-1]
            let clazz = `panel service-panel ${panel.class}`;
            rowMarkup.push(<ObjectLink key={panel.key} url={panel.url} children={<Column width={3}>
                <div className={clazz}>
                    <p>{t(panel.description)}</p>
                    <h1>{t(panel.title)}</h1>
                </div>
            </Column>} />);
        }

        return rowMarkup;
    }

    const getGrid = () => {
        let remainder = props.children.length % 4;
        let rows = (props.children.length - remainder)/4;
        rows = remainder > 0 ? rows+=1 : rows;

        let rowsMarkup = []
        for(let i=0; i<rows; ++i) {
            rowsMarkup.push(<Row key={"row_" + i}>
                {getRow(i, rows, remainder).map((row, index) => {
                    return row;
                })}
            </Row>)
        }

        return rowsMarkup;
    } 

    return (<>
        {getGrid().map((rows, index) => {
            return rows;
        })}
    </>);
}

export default LinkGrid;
{
    "logo": "InsyncPharmacy.svg",
    "company_name": "Insync Pharmacy",
    "languages": [{
        "key": "OeTuKnENrkClbDJ1OUYt2g==",
        "title": "layout.nav.languages.english",
        "code": "en"
    }, {
        "key": "3DJH7LjbSke8TdN3gxSgXw==",
        "title": "layout.nav.languages.welsh",
        "code": "cy"
    }],
    "menu": {
        "children": [{
            "key": "u1oVaYsWnU2lZIqE23+NYg==",
            "title": "layout.nav.home",
            "url": "/"
        }, {
            "key": "sDJy6rooGUWUcsLNW+yrdw==",
            "title": "layout.nav.advicelinks.title",
            "submenu": {
                "back": "layout.nav.menu",
                "columns": [{
                    "key": "4hkLkWMAtkqhxYceOgDaAg==",
                    "title": "layout.nav.advicelinks.services.title",
                    "go_back": "layout.nav.advicelinks.title",
                    "links": [{
                        "key": "Zd6tN3Fe5Em+36gPpZ85WA==",
                        "title": "layout.nav.advicelinks.services.ordering",
                        "url": "/Ordering"
                    }, {
                        "key": "OiOP7omN6Ui2FMgNLaf2+g==",
                        "title": "layout.nav.advicelinks.services.weightloss",
                        "url": "/WeightLoss"
                    }, {
                        "key": "MDfYrixeyUW0WjqjrPMm1Q==",
                        "title": "layout.nav.advicelinks.services.travelclinic",
                        "url": "/Travel"
                    }, {
                        "key": "jVnTGta1ZEyvRFMUToKM2Q==",
                        "title": "layout.nav.advicelinks.services.travelriskassessment",
                        "url": "/TravelRiskAssessment"
                    },{
                        "key": "eDLeEe1U206/+8ejyEOUtw==",
                        "title": "layout.nav.advicelinks.services.vaccines",
                        "url": "/InfluenzaVaccinations"
                    }, {
                        "key": "iGsF/gG7nk61IXWlOg9Z+g==",
                        "title": "layout.nav.advicelinks.services.ols",
                        "url": "/OtherLocalServices"
                    }, {
                        "key": "Z8t0x1Df2kaVp9Tt7gdzbg==",
                        "title": "layout.nav.advicelinks.services.sales",
                        "url": "/PharmacySales"
                    }, {
                        "key": "GwymJ9lB7Uup9qACPCY9DA==",
                        "title": "layout.nav.advicelinks.services.earwaxremoval",
                        "url": "/EarWaxRemoval"
                    }, {
                        "key": "poKCjDB8pk6KykrFJrwQtg==",
                        "title": "layout.nav.advicelinks.services.commonailments",
                        "url": "/CommonAilments"
                    }]
                }, {
                    "key": "Ko8Ms+ih/kiykLo3VKaMYQ==",
                    "title": "layout.nav.advicelinks.leaflets.title",
                    "go_back": "layout.nav.advicelinks.title",
                    "links": [{
                        "key": "86XFlHdV2k+893RAaZZqlQ==",
                        "title": "layout.nav.advicelinks.leaflets.dischargereview",
                        "url": "./documents/Insync Pharmacy DMR leaflet March 1.pdf",
                        "open_in_new_tab": "true"
                    }, {
                        "key": "ZUUEcfyTcUWNipHApRN9MA==",
                        "title": "layout.nav.advicelinks.leaflets.helptakingmedicine",
                        "url": "./documents/Insync Pharmacy help taking medicines leaflet March 1.pdf",
                        "open_in_new_tab": "true"
                    }, {
                        "key": "ahkH/v5hO0ihMBKTgiivMQ==",
                        "title": "layout.nav.advicelinks.leaflets.medicinereview",
                        "url": "./documents/Insync Pharmacy MUR leaflet March.pdf",
                        "open_in_new_tab": "true"
                    }, { 
                        "key": "s79RayASxU2kzQzl6J78lQ==",
                        "title": "layout.nav.advicelinks.leaflets.practices",
                        "url": "./documents/Insync Pharmacy practice leaflet March 1.pdf",
                        "open_in_new_tab": "true"
                    }, {
                        "key": "r3cnh4kavk2NHaK34rkEZA==",
                        "title": "layout.nav.advicelinks.leaflets.prescriptions",
                        "url": "./documents/Insync Pharmacy prescription leaflet March 1.pdf",
                        "open_in_new_tab": "true"
                    }, {
                        "key": "cnd5gFaPJkSgP7hrBaQcbw==",
                        "title": "layout.nav.advicelinks.leaflets.weightloss",
                        "url": "./documents/Insync Pharmacy weight loss leaflet March 1.pdf",
                        "open_in_new_tab": "true"
                    }]
                }, {
                    "key": "ve4UtWJrjkSW7TrcMcU2+w==",
                    "title": "layout.nav.advicelinks.externallinks.title",
                    "go_back": "layout.nav.advicelinks.title",
                    "links": [{
                        "key": "DTuCH7PEEEWuwkdcBpq5NQ==",
                        "title": "www.patient.co.uk",
                        "url": "http://www.patient.co.uk",
                        "open_in_new_tab": "true"
                    }]
                }]
            }
        }, {
            "key": "j/ZwqJToGE+5lf2AN+YBoA==",
            "title": "layout.nav.findus",
            "url": "/FindUs"
        }, {
            "key": "4MlKrhA6PESTTWAJRM524w==",
            "title": "layout.nav.about",
            "url": "/About"
        }, {
            "key": "RlNN6wJR0kS6RfSNQs6+Ew==",
            "title": "layout.nav.contactus",
            "url": "/Contact",
            "style": "button",
            "class": "contact-button"
        }]
    },
    "footer": {
        "children": [{
            "key": "lt+zF95QoUqDPxd4uIVaeg==",
            "type": "link",
            "value": "layout.footer.checkpharmregstatus",
            "url": "https://www.pharmacyregulation.org/registers/pharmacy/nameortown/insync",
            "class": "footer-link"
        }]
    }
}
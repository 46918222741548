import * as React from 'react';
import { useTranslation } from 'react-i18next';

const ImageLink = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <a href={props.url}>
            <img alt={props.alt} src={`images/${props.image}`} style={props.style} height={props.height} />
        </a>);
}

export default ImageLink;
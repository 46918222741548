import React, { useReducer, useEffect } from 'react';
import { Button, Form, Radio, Select } from 'semantic-ui-react'
import {
    DateInput,
} from 'semantic-ui-calendar-react-18';
import _LayoutView from './_LayoutView';
import Insync from '../api/api_insync'
import { Loader } from 'semantic-ui-react'

const initialState = {
    step: 'page1',
    name: '',
    email: '',
    dob: '',
    phone: '',
    address: '',
    gpPractice: '',
    gpPracticeAddress: '',
    dateOfDeparture: '',
    destinations: [{
        countryToBeVisted: '',
        exactLocation: '',
        selectedAreaType: '',
        lengthOfStay: '',
    }],
    insurance: false,
    futureTrips: false,
    purposeHoliday: false,
    purposeBusinessTrip: false,
    purposeExpatriate: false,
    purposeVolunteer: false,
    purposeHealthcareWorker: false,
    purposeHotelStay: false,
    purposeCruise: false,
    purposeSafari: false,
    purposePilgrimage: false,
    purposeMedicalTourism: false,
    purposeBackpacking: false,
    purposeCamping: false,
    purposeAdventure: false,
    purposeDiving: false,
    purposeVisitingFriends: false,
    purposeAdditionalInformation: '',
    mh_anyAllergies: false,
    mh_anyAllergiesDetails: '',
    mh_severeVaccineReaction: false,
    mh_severeVaccineReactionDetails: '',
    mh_tendencyToFaint: false,
    mh_tendencyToFaintDetails: '',
    mh_surgicalOperations: false,
    mh_surgicalOperationsDetails: '',
    mh_recentChemo: false,
    mh_recentChemoDetails: '',
    mh_anaemia: false,
    mh_anaemiaDetails: '',
    mh_bleeding: false,
    mh_bleedingDetails: '',
    mh_heartDisease: false,
    mh_heartDiseaseDetails: '',
    mh_diabetes: false,
    mh_diabetesDetails: '',
    mh_disability: false,
    mh_disabilityDetails: '',
    mh_epilepsy: false,
    mh_epilepsyDetails: '',
    mh_stomach: false,
    mh_stomachDetails: '',
    mh_liver: false,
    mh_liverDetails: '',
    mh_hiv: false,
    mh_hivDetails: '',
    mh_immune: false,
    mh_immuneDetails: '',
    mh_mentalHealth: false,
    mh_mentalHealthDetails: '',
    mh_neuro: false,
    mh_neuroDetails: '',
    mh_lung: false,
    mh_lungDetails: '',
    mh_joint: false,
    mh_jointDetails: '',
    mh_spleen: false,
    mh_spleenDetails: '',
    mh_anyOther: false,
    mh_anyOtherDetails: '',
    mh_pregnant: false,
    mh_pregnantDetails: '',
    mh_breastFeeding: false,
    mh_breastFeedingDetails: '',
    mh_pregnancy: false,
    mh_pregnancyDetails: '',
    mh_womanJoint: false,
    mh_womanJointDetails: '',
    mh_womanSpleen: false,
    mh_womanSpleenDetails: '',
    medication: '',
    additionalInformation: '',
    formSent: false,
    formSending: false,
    showAreaTypeValidation: false
}

const reducer = (state, action) => {
    if (action.type == 'reset') {
        return initialState;
    }

    const result = { ...state };
    result[action.type] = action.value;
    return result;
}

const areaType = [
    { key: 'c', text: 'City', value: 'city' },
    { key: 'r', text: 'Rural', value: 'rural' },
    { key: 'b', text: 'Both', value: 'both' },
]

const TravelRiskAssessmentView = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        step, name, email, dob, phone, address, gpPractice, gpPracticeAddress, dateOfDeparture,
        destinations, formSent, formSending,
        insurance, futureTrips, purposeHoliday, purposeBusinessTrip, purposeExpatriate, purposeVolunteer,
        purposeHealthcareWorker, purposeHotelStay, purposeCruise, purposeSafari, purposePilgrimage,
        purposeMedicalTourism, purposeBackpacking, purposeCamping, purposeAdventure, purposeDiving, purposeVisitingFriends,
        purposeAdditionalInformation, mh_anyAllergies, mh_anyAllergiesDetails,
        mh_severeVaccineReaction, mh_severeVaccineReactionDetails, mh_tendencyToFaint, mh_tendencyToFaintDetails,
        mh_surgicalOperations, mh_surgicalOperationsDetails, mh_recentChemo, mh_recentChemoDetails, mh_anaemia,
        mh_anaemiaDetails, mh_bleeding, mh_bleedingDetails, mh_heartDisease, mh_heartDiseaseDetails, mh_diabetes,
        mh_diabetesDetails, mh_disability, mh_disabilityDetails, mh_epilepsy, mh_epilepsyDetails, mh_stomach,
        mh_stomachDetails, mh_liver, mh_liverDetails, mh_hiv, mh_hivDetails, mh_immune, mh_immuneDetails,
        mh_mentalHealth, mh_mentalHealthDetails, mh_neuro, mh_neuroDetails, mh_lung, mh_lungDetails, mh_joint,
        mh_jointDetails, mh_spleen, mh_spleenDetails, mh_anyOther, mh_anyOtherDetails, mh_pregnant, mh_pregnantDetails,
        mh_breastFeeding, mh_breastFeedingDetails, mh_pregnancy, mh_pregnancyDetails, mh_womanJoint, mh_womanJointDetails,
        mh_womanSpleen, mh_womanSpleenDetails, medication, additionalInformation, showAreaTypeValidation
    } = state;

    const handleChange = (fieldName, value) => {
        dispatch({ type: fieldName, value: value });
    }

    const handleDestinationChange = (index, fieldName, value) => {
        destinations[index][fieldName] = value;
        dispatch({ type: 'destinations', value: destinations });
    }

    const handleSubmit = () => {
        if (step === 'page1') {
            window.scrollTo(0, 0);
            dispatch({ type: 'step', value: 'page2' });
        } else if (step === 'page2') {
            let validated = true;
            for (var i = 0; i < destinations.length; i++) {
                if (!destinations[i].selectedAreaType) {
                    validated = false;
                    dispatch({ type: 'showAreaTypeValidation', value: true })
                    break;
                }
            }
            if (validated) {
                window.scrollTo(0, 0);
                dispatch({ type: 'showAreaTypeValidation', value: false })
                dispatch({ type: 'step', value: 'page3' });
            }
        } else if (step === 'page3') {
            window.scrollTo(0, 0);
            dispatch({ type: 'step', value: 'page4' });
        } else {
            dispatch({ type: 'formSending', value: true });
            //submit
            Insync.sendTravelRiskForm({
                pharmacy: 'Insync Pharmacy',
                name: name,
                email: email,
                dob: dob,
                phone: phone,
                address: address,
                gpPractice: gpPractice,
                gpPracticeAddress: gpPracticeAddress,
                dateOfDeparture: dateOfDeparture,
                destinations: destinations,
                insurance: insurance,
                futureTrips: futureTrips,
                purposeHoliday: purposeHoliday,
                purposeBusinessTrip: purposeBusinessTrip,
                purposeExpatriate: purposeExpatriate,
                purposeVolunteer: purposeVolunteer,
                purposeHealthcareWorker: purposeHealthcareWorker,
                purposeHotelStay: purposeHotelStay,
                purposeCruise: purposeCruise,
                purposeSafari: purposeSafari,
                purposePilgrimage: purposePilgrimage,
                purposeMedicalTourism: purposeMedicalTourism,
                purposeBackpacking: purposeBackpacking,
                purposeCamping: purposeCamping,
                purposeAdventure: purposeAdventure,
                purposeDiving: purposeDiving,
                purposeVisitingFriends: purposeVisitingFriends,
                purposeAdditionalInformation: purposeAdditionalInformation,
                mh_anyAllergies: mh_anyAllergies,
                mh_anyAllergiesDetails: mh_anyAllergiesDetails,
                mh_severeVaccineReaction: mh_severeVaccineReaction,
                mh_severeVaccineReactionDetails: mh_severeVaccineReactionDetails,
                mh_tendencyToFaint: mh_tendencyToFaint,
                mh_tendencyToFaintDetails: mh_tendencyToFaintDetails,
                mh_surgicalOperations: mh_surgicalOperations,
                mh_surgicalOperationsDetails: mh_surgicalOperationsDetails,
                mh_recentChemo: mh_recentChemo,
                mh_recentChemoDetails: mh_recentChemoDetails,
                mh_anaemia: mh_anaemia,
                mh_anaemiaDetails: mh_anaemiaDetails,
                mh_bleeding: mh_bleeding,
                mh_bleedingDetails: mh_bleedingDetails,
                mh_heartDisease: mh_heartDisease,
                mh_heartDiseaseDetails: mh_heartDiseaseDetails,
                mh_diabetes: mh_diabetes,
                mh_diabetesDetails: mh_diabetesDetails,
                mh_disability: mh_disability,
                mh_disabilityDetails: mh_disabilityDetails,
                mh_epilepsy: mh_epilepsy,
                mh_epilepsyDetails: mh_epilepsyDetails,
                mh_stomach: mh_stomach,
                mh_stomachDetails: mh_stomachDetails,
                mh_liver: mh_liver,
                mh_liverDetails: mh_liverDetails,
                mh_hiv: mh_hiv,
                mh_hivDetails: mh_hivDetails,
                mh_immune: mh_immune,
                mh_immuneDetails: mh_immuneDetails,
                mh_mentalHealth: mh_mentalHealth,
                mh_mentalHealthDetails: mh_mentalHealthDetails,
                mh_neuro: mh_neuro,
                mh_neuroDetails: mh_neuroDetails,
                mh_lung: mh_lung,
                mh_lungDetails: mh_lungDetails,
                mh_joint: mh_joint,
                mh_jointDetails: mh_jointDetails,
                mh_spleen: mh_spleen,
                mh_spleenDetails: mh_spleenDetails,
                mh_anyOther: mh_anyOther,
                mh_anyOtherDetails: mh_anyOtherDetails,
                mh_pregnant: mh_pregnant,
                mh_pregnantDetails: mh_pregnantDetails,
                mh_breastFeeding: mh_breastFeeding,
                mh_breastFeedingDetails: mh_breastFeedingDetails,
                mh_pregnancy: mh_pregnancy,
                mh_pregnancyDetails: mh_pregnancyDetails,
                mh_womanJoint: mh_womanJoint,
                mh_womanJointDetails: mh_womanJointDetails,
                mh_womanSpleen: mh_womanSpleen,
                mh_womanSpleenDetails: mh_womanSpleenDetails,
                medication: medication,
                additionalInformation: additionalInformation,
            }, () => {
                window.scrollTo(0, 0);
                dispatch({ type: 'formSent', value: true });
            }, (error) => {
                console.log(error)
            })
        }
    }

    const handlePrevious = (e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
        if (step === 'page4') {
            dispatch({ type: 'step', value: 'page3' });
        } else if (step === 'page3') {
            dispatch({ type: 'step', value: 'page2' });
        } else if (step === 'page2') {
            console.log('wtf')
            dispatch({ type: 'step', value: 'page1' });
        } else {
            //submit
        }
    }

    const addDestination = (e) => {
        e.preventDefault();
        if (destinations.length < 10) {
            destinations.push({
                countryToBeVisted: '',
                exactLocation: '',
                selectedAreaType: '',
                lengthOfStay: '',
            })
            dispatch({ type: 'destinations', value: destinations });
        }
    }

    const removeDestination = (e, index) => {
        e.preventDefault();
        destinations.splice(index, 1);
        dispatch({ type: 'destinations', value: destinations });
    }

    const messageSuccess = () => {
        return (<div className="row">
            <div className="col-12">
                <div className="panel">
                    <h1>Thank you for submitting your travel risk assessment</h1>
                    <p>Your risk assessment has been successfully submitted, all information received will always remain confidential. We will contact you as soon as we review your enquiry.</p>
                    <div style={{ marginTop: 40 }}><a className="btn" href="/TravelRiskAssessment">Add another patient</a></div>
                    <div style={{ marginTop: 40 }}><a href="/" >Return to Homepage</a></div>
                </div>
            </div>
        </div>)
    }

    const getPage = () => {
        switch (step) {
            case 'page1': return page1();
            case 'page2': return page2();
            case 'page3': return page3();
            case 'page4': return page4();
        }
    }

    const getPageTitle = () => {
        switch (step) {
            case 'page1': return 'Patient Details';
            case 'page2': return 'Please supply information about your trip in the section below';
            case 'page3': return 'Type of travel and purpose of trip - Please tick all that apply';
            case 'page4': return 'Please supply details of your personal medical history - Please tick all that apply';
        }
    }

    const page1 = () => {
        return (
            <>
                <Form.Group widths='equal'>
                    <Form.Input required label='Name' value={name} onChange={(e) => handleChange('name', e.target.value)} />
                    <Form.Input
                        required
                        label='Email'
                        type='email'
                        onChange={(e) => handleChange('email', e.target.value)}
                        name='email'
                        value={email} />
                </Form.Group>
                <Form.Group widths='equal'>
                    <DateInput
                        dateFormat='DD/MM/yyyy'
                        initialDate={"01/01/1990"}
                        startMode="year"
                        required
                        label="Date of Birth"
                        name="dob"
                        value={dob}
                        iconPosition="left"
                        onChange={(e, { name, value }) => handleChange('dob', value)}
                        closable

                    />
                    <Form.Input required label='Phone Number' value={phone} type="number" onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }} onChange={(e) => handleChange('phone', e.target.value)} />
                </Form.Group>
                <Form.TextArea required label='Address' value={address} onChange={(e) => handleChange('address', e.target.value)} />
                <Form.Input required fluid label='GP Practice Name' value={gpPractice} onChange={(e) => handleChange('gpPractice', e.target.value)} />
                <Form.TextArea required label='GP Practice Address' value={gpPracticeAddress} onChange={(e) => handleChange('gpPracticeAddress', e.target.value)} />
                <Button type='button' className='btn submit-btn' style={{ visibility: "hidden" }}>-</Button>
                <Button type='submit' className='btn submit-btn' style={{ float: "right", width: 100 }} >Next</Button>
            </>)
    }

    const getDetstinations = () => {
        let destTags = [];
        console.log(destinations.length)
        for (var i = 1; i < destinations.length; i++) {
            destTags.push(<Form.Group inline key={`destination_${i}`}>
                <Form.Input
                    name={i}
                    required
                    fluid
                    label='Country to be visited'
                    value={destinations[i].countryToBeVisted}
                    onChange={(e, { name, value }) => handleDestinationChange(name, 'countryToBeVisted', e.target.value)} />
                <Form.Input
                    name={i}
                    required
                    fluid
                    label='Exact Location or region'
                    value={destinations[i].exactLocation}
                    onChange={(e, { name, value }) => handleDestinationChange(name, 'exactLocation', e.target.value)} />
                <Form.Select
                    name={i}
                    style={{ minWidth: 150 }}
                    required
                    fluid
                    label='AreaType'
                    options={areaType}
                    placeholder=''
                    value={destinations[i].selectedAreaType}
                    onChange={(e, { name, value }) => handleDestinationChange(name, 'selectedAreaType', value)}
                />
                <Form.Input
                    name={i}
                    required
                    fluid
                    type='number'
                    min='1'
                    label='Length of stay (days)'
                    value={destinations[i].lengthOfStay}
                    onChange={(e, { name, value }) => handleDestinationChange(name, 'lengthOfStay', e.target.value)} />
                <Button name={i} className='btn' style={{ float: "right", marginTop: 25, height: 42 }} onClick={(e, { name, value }) => { removeDestination(e, name) }}>x</Button>
            </Form.Group>);
        }
        console.log(destTags.length)
        console.log(destTags)
        return <>{destTags}</>
    }

    const page2 = () => {
        return (
            <>
                <DateInput
                    closable
                    dateFormat='DD/MM/yyyy'
                    label="Date of Departure"
                    required
                    name="dateOfDeparture"
                    placeholder="From"
                    value={dateOfDeparture}
                    iconPosition="left"
                    onChange={(e, { name, value }) => handleChange('dateOfDeparture', value)}
                />
                <Form.Group inline key={`destination_0`}>
                    <Form.Input
                        required
                        fluid
                        label='Country to be visited'
                        value={destinations[0].countryToBeVisted}
                        onChange={(e) => handleDestinationChange(0, 'countryToBeVisted', e.target.value)} />
                    <Form.Input
                        required
                        fluid
                        label='Exact Location or region'
                        value={destinations[0].exactLocation}
                        onChange={(e) => handleDestinationChange(0, 'exactLocation', e.target.value)} />
                    <Form.Select
                        style={{ minWidth: 150 }}
                        required
                        fluid
                        label='AreaType'
                        options={areaType}
                        placeholder=''
                        value={destinations[0].selectedAreaType}
                        onChange={(e, { name, value }) => handleDestinationChange(0, 'selectedAreaType', value)}
                    />
                    <Form.Input
                        required
                        fluid
                        type='number'
                        min='1'
                        label='Length of stay (days)'
                        value={destinations[0].lengthOfStay}
                        onChange={(e) => handleDestinationChange(0, 'lengthOfStay', e.target.value)} />
                    <Button className='btn submit-btn' type="button" style={{ float: "right", marginTop: 25, height: 42 }} onClick={addDestination}>Add ...</Button>
                </Form.Group>
                {getDetstinations()}
                <Form.Field
                    label='Have you taken out insurance for this trip?'
                    control='input'
                    type='checkbox'
                    checked={insurance}
                    onChange={(e) => handleChange('insurance', e.target.checked)} />
                <Form.Field
                    label='Do you plan to travel abroad again in the future?'
                    control='input'
                    type='checkbox'
                    checked={futureTrips}
                    onChange={(e) => handleChange('futureTrips', e.target.checked)} />
                <div style={{ paddingTop: 30 }} />
                {showAreaTypeValidation && <p style={{ color: 'red', fontSize: 14 }}>Please fill in all area types</p>}
                <Button type='button' className='btn submit-btn' style={{ width: 100 }} onClick={handlePrevious}>Previous</Button>
                <Button type='submit' className='btn submit-btn' style={{ float: "right", width: 100 }} >Next</Button>
            </>)
    }

    const page3 = () => {
        return (
            <>
                <Form.Field label='Holiday' control='input' type='checkbox' checked={purposeHoliday} onChange={(e) => handleChange('purposeHoliday', e.target.checked)} />
                <Form.Field label='Business Trip' control='input' type='checkbox' checked={purposeBusinessTrip} onChange={(e) => handleChange('purposeBusinessTrip', e.target.checked)} />
                <Form.Field label='Expatriate' control='input' type='checkbox' checked={purposeExpatriate} onChange={(e) => handleChange('purposeExpatriate', e.target.checked)} />
                <Form.Field label='Volunteer Work' control='input' type='checkbox' checked={purposeVolunteer} onChange={(e) => handleChange('purposeVolunteer', e.target.checked)} />
                <Form.Field label='Healthcare Worker' control='input' type='checkbox' checked={purposeHealthcareWorker} onChange={(e) => handleChange('purposeHealthcareWorker', e.target.checked)} />
                <Form.Field label='Staying in Hotel' control='input' type='checkbox' checked={purposeHotelStay} onChange={(e) => handleChange('purposeHotelStay', e.target.checked)} />
                <Form.Field label='Cruise ship trip' control='input' type='checkbox' checked={purposeCruise} onChange={(e) => handleChange('purposeCruise', e.target.checked)} />
                <Form.Field label='Safari' control='input' type='checkbox' checked={purposeSafari} onChange={(e) => handleChange('purposeSafari', e.target.checked)} />
                <Form.Field label='Pilgrimage' control='input' type='checkbox' checked={purposePilgrimage} onChange={(e) => handleChange('purposePilgrimage', e.target.checked)} />
                <Form.Field label='Medical Tourism' control='input' type='checkbox' checked={purposeMedicalTourism} onChange={(e) => handleChange('purposeMedicalTourism', e.target.checked)} />
                <Form.Field label='Backpacking' control='input' type='checkbox' checked={purposeBackpacking} onChange={(e) => handleChange('purposeBackpacking', e.target.checked)} />
                <Form.Field label='Camping/hostels' control='input' type='checkbox' checked={purposeCamping} onChange={(e) => handleChange('purposeCamping', e.target.checked)} />
                <Form.Field label='Adventure' control='input' type='checkbox' checked={purposeAdventure} onChange={(e) => handleChange('purposeAdventure', e.target.checked)} />
                <Form.Field label='Diving' control='input' type='checkbox' checked={purposeDiving} onChange={(e) => handleChange('purposeDiving', e.target.checked)} />
                <Form.Field label='Visiting Friends/family' control='input' type='checkbox' checked={purposeVisitingFriends} onChange={(e) => handleChange('purposeVisitingFriends', e.target.checked)} />
                <div style={{ paddingTop: 30 }} />
                <Form.TextArea
                    label='Additional Information'
                    value={purposeAdditionalInformation}
                    onChange={(e) => handleChange('purposeAdditionalInformation', e.target.value)} />
                <div style={{ paddingTop: 30 }} />
                <Button type='button' className='btn submit-btn' style={{ width: 100 }} onClick={handlePrevious}>Previous</Button>
                <Button type='submit' className='btn submit-btn' style={{ float: "right", width: 100 }} >Next</Button>
            </>)
    }

    const getMedicalHistoryItem = (label, value) => {
        return (
            <>
                <Form.Group inline>
                    <Form.Field label={label} control='input' type='checkbox' checked={state[value]} onChange={(e) => handleChange(value, e.target.checked)} />
                </Form.Group>
                {state[value] && <Form.TextArea
                    label='Details'
                    value={state[value + 'Details']}
                    onChange={(e) => handleChange(value + 'Details', e.target.value)} />}
            </>
        )
    }

    const page4 = () => {
        return (
            <>
                {getMedicalHistoryItem('Are you pregnant?', 'mh_pregnant')}
                {getMedicalHistoryItem('Any allergies including food, latex, medication?', 'mh_anyAllergies')}
                {getMedicalHistoryItem('Severe reaction to a vaccine before?', 'mh_severeVaccineReaction')}
                {getMedicalHistoryItem('Tendency to faint with injections?', 'mh_tendencyToFaint')}
                {getMedicalHistoryItem('Any surgical operations in the past, including e.g. your spleen or thymus gland removed', 'mh_surgicalOperations')}
                {getMedicalHistoryItem('Recent chemotherapy/radiotherapy/organ transplant', 'mh_recentChemo')}
                {getMedicalHistoryItem('Anaemia', 'mh_anaemia')}
                {getMedicalHistoryItem('Bleeding / Clotting Disorders (including history of DVT)', 'mh_bleeding')}
                {getMedicalHistoryItem('Heart Disease (e.g. angina, high blood pressure)', 'mh_heartDisease')}
                {getMedicalHistoryItem('Diabetes', 'mh_diabetes')}
                {getMedicalHistoryItem('Disability', 'mh_disability')}
                {getMedicalHistoryItem('Epilepsy/Seizures', 'mh_epilepsy')}
                {getMedicalHistoryItem('Gastrointestinal (stomach) complaints', 'mh_stomach')}
                {getMedicalHistoryItem('Liver and/or Kidney problems', 'mh_liver')}
                {getMedicalHistoryItem('HIV/AIDS', 'mh_hiv')}
                {getMedicalHistoryItem('Immune system Conditions', 'mh_immune')}
                {getMedicalHistoryItem('Mental Health Issues (anxiety, depression)', 'mh_mentalHealth')}
                {getMedicalHistoryItem('Neurological (nervous system) illness', 'mh_neuro')}
                {getMedicalHistoryItem('Respiratory (Lungs) Disease', 'mh_lung')}
                {getMedicalHistoryItem('Rheumatology (joint) conditions', 'mh_joint')}
                {getMedicalHistoryItem('Spleen Problems', 'mh_spleen')}
                {getMedicalHistoryItem('Any other conditions?', 'mh_anyOther')}
                {getMedicalHistoryItem('Are you breast feeding?', 'mh_breastFeeding')}
                {getMedicalHistoryItem('Are you planning pregnancy while away?', 'mh_pregnancy')}
                {getMedicalHistoryItem('Rheumatology (joint) conditions', 'mh_womanJoint')}
                {getMedicalHistoryItem('Spleen Problems', 'mh_womanSpleen')}
                <div style={{ paddingTop: 30 }} />
                <Form.TextArea
                    label='Are you Taking any Medication? (including prescribed, purchased or a contraceptive pill?'
                    value={medication}
                    onChange={(e) => handleChange('medication', e.target.value)} />
                <Form.TextArea
                    label='Any Additional Information'
                    value={additionalInformation}
                    onChange={(e) => handleChange('additionalInformation', e.target.value)} />
                <div style={{ paddingTop: 30 }} />
                <Button className='btn submit-btn' disabled={formSending} style={{ width: 100 }} onClick={handlePrevious}>Previous</Button>
                <Button type='submit' className='btn submit-btn' style={{ float: "right", width: 100 }} disabled={formSending}>Submit</Button>
                {formSending && <Loader style={{ marginLeft: 10, marginBottom: 10 }} active inline />}
            </>
        )
    }

    return (
        <_LayoutView history={props.history} title="Travel Risk Assessment Form" breadcrumb="Travel Risk Assessment Form">
            {(formSent && messageSuccess()) ||
                <div className="row">
                    <div className="col-6">
                        <div className="panel">
                            <h1>{getPageTitle()}</h1>
                            <Form onSubmit={handleSubmit}>
                                {getPage()}
                            </Form>
                        </div>
                    </div>
                </div>}
        </_LayoutView>
    );
}

export default TravelRiskAssessmentView;
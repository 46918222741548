import rest from './rest'

export default class insync {
    static getOTL = (successCallback, errorCallback) => {
        rest.get('remedi-api', 'ols', null, successCallback, errorCallback);
    }
    
    static getSurgeries = (successCallback, errorCallback) => {
        rest.get('insync', 'surgeries/get', null, successCallback, errorCallback);
    }

    static sendContactMessage = (contact, successCallback, errorCallback) => {
        rest.post('remedi-api', 'contact/send', contact, successCallback, errorCallback);
    }

    static sendTravelRiskForm = (travelRiskForm, successCallback, errorCallback) => {
        rest.post('remedi-api', 'travelRiskForm', travelRiskForm, successCallback, errorCallback);
    }

    static register = (registration, successCallback, errorCallback) => {
        rest.post('insync', 'registration/send', registration, successCallback, errorCallback);
    }

    static getRegistration = (id, successCallback, errorCallback) => {
        rest.get('insync', 'registration/get/' + id, null, successCallback, errorCallback);
    }
}
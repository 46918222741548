import * as React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const Twitter = (props) => {
    return (
        <TwitterTimelineEmbed
            sourceType="profile"
            screenName={props.handle}
            options={{ height: 500 }}
        />);
}

export default Twitter;
let azureHostPrefix;

const hostname = window && window.location && window.location.hostname;

if(hostname === 'insync-pharmacy.co.uk' || hostname === 'www.insync-pharmacy.co.uk') {
    azureHostPrefix = 'live';
} else {
    azureHostPrefix = 'dev';
}

export const AZURE_HOST_PREFIX = `${azureHostPrefix}`;
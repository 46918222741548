import React, { useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Row from './Row';
import Column from './Column';

const Carousel = (props) => {
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        props.items.forEach(element => {
            if(!element.thumbnail.startsWith("images/")) {
                element.thumbnail = `images/${element.thumbnail}`;
            }
        });
    }, [])

    const onLearnMoreClick = (e) => {
        e.preventDefault();
        let item = props.items.find(i => i.id === e.target.id);
        navigate(item.learn_more_url);
    }

    const onRenderCarouselItem = (item) => {
        //TODO const onImageError = props.onImageError;// || _handleImageError;
    
        return (
            <div className='image-gallery-image'>
                {item.learn_more_url && <button id={item.id} className="banner-link btn" onClick={(e) => onLearnMoreClick(e)}>{t('page.home.banners.learnmore')}</button>}
                <img
                    src={`images/${item.original}`}
                    alt={item.originalAlt}
                    srcSet={item.srcSet}
                    sizes={item.sizes}
                    title={item.originalTitle}
                //TODO onLoad={props.onImageLoad}
                //TODO onError={onImageError}
                />
                {
                    item.description &&
                    <span className='image-gallery-description'>
                        {t(item.description)}
                    </span>
                }
            </div>
        );
    };

    return (<Row>
                <Column width={12}>
                    <ImageGallery
                        items={props.items}
                        thumbnailPosition='right'
                        showNav={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showIndex={true}
                        autoPlay={true}
                        slideInterval={5000}
                        renderItem={(e) => onRenderCarouselItem(e)} />
        </Column>
    </Row>);
}

export default Carousel;
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const Link = (props) => {
    const { t, i18n } = useTranslation();
    let clazz = props.class ? `mojo-link ${props.class}` : 'mojo-link';

    return (
        <a className={clazz} target="_blank" href={props.url}>
            {props.icon && <img style={{ width: 20, position: 'relative', top: 5, marginRight: 10 }} src={`images/${props.icon}`} alt="Instagram Logo" />}
            {t(props.value)}
        </a>);
}

export default Link;
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Facebook = (props) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        var d = document;
        var s = 'script';
        var id = 'facebook-jssdk';
    
        var js, fjs = document.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v2.8";
        fjs.parentNode.insertBefore(js, fjs);
    }, [])

    return (
        <div className="fb-page" data-href={props.url} data-tabs="timeline" data-width="500" 
            data-add-back-btndata-height="500" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" 
            data-show-facepile="false"><blockquote cite={props.url} 
            className="fb-xfbml-parse-ignore">
                <a href={props.url}>{t(props.title)}</a></blockquote>
        </div>);
}

export default Facebook;
import React, { useReducer, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../components/Breadcrumb';
import _layout from '../content/_layout.json'
import cf from '../components/ComponentFactory';
import ImageLink from '../components/ImageLink';
import { Dropdown } from 'semantic-ui-react'
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const initialState = {
    languages: {},
    selectedLanguage: '',
    menuItems: {},
    burgerMenuClicked: false,
    isDesktop: window.innerWidth >= 1170
};

const reducer = (state, action) => {
    if (action.type == 'reset') {
        return initialState;
    }

    const result = { ...state };
    result[action.type] = action.value;
    return result;
}

const _LayoutView = (props) => {
    const { t, i18n } = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { languages, selectedLanguage, menuItems, burgerMenuClicked, isDesktop } = state;
    const [cookies, setCookie] = useCookies(['remedi-language']);
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const addLanguage = (title, code) => {
        languages[title] = code;
        dispatch({ type: 'languages', value: languages });
    }

    const addMenuItem = (item) => {
        menuItems[item] = false;
        dispatch({ type: 'menuItems', value: menuItems });
    }

    const onClickMenuItem = (e, item) => {
        menuItems[item] = !menuItems[item];
        dispatch({ type: 'menuItems', value: menuItems });
    };

    const setSelectedLanguage = (e, {value}) => {
        _setSelectedLanguage(value);
    }

    const _setSelectedLanguage = (value) => {
        i18n.changeLanguage(value)
        console.log(value)
        dispatch({ type: 'selectedLanguage', value: value });
        setCookie('remedi-language', value)
    }

    const onClickBurgerMenu = e => {
        dispatch({ type: 'burgerMenuClicked', value: !burgerMenuClicked });
    }

    const menuItemClicked = menuItems => {
        return Object.values(menuItems).some(m => m === true);
    }

    useEffect(() => {
        dispatch({ type: 'reset' });

        var languageCookie = cookies['remedi-language']
        var language =  languageCookie ? languageCookie : 'en';
        
        if (queryParams.lang) {
            language = queryParams.lang;
        }
        _setSelectedLanguage(language);


        window.addEventListener("resize", () => { dispatch({ type: 'reset' }); });

        return () => {
            window.removeEventListener("resize", () => { dispatch({ type: 'reset' }); });
        }
    }, [])

    return (
        <div>
            <header className={"cd-main-header" + (burgerMenuClicked ? " nav-is-visible" : "")}>
                <div className="cd-logo">
                    <ImageLink url={"/"} image={_layout.logo} />
                </div>
                {isDesktop && <Navigation history={props.history}
                    menuItems={menuItems}
                    addLanguage={addLanguage}
                    languages={languages}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    addMenuItem={addMenuItem}
                    onClickMenuItem={onClickMenuItem}
                    burgerMenuClicked={burgerMenuClicked} />}
                <ul className="cd-header-buttons">
                    <li><a className={"cd-nav-trigger" + (burgerMenuClicked ? " nav-is-visible" : "")}
                        onClick={onClickBurgerMenu}
                        href="#cd-primary-nav"><span></span></a></li>
                </ul>
            </header>
            <main className={"cd-main-content" + (burgerMenuClicked ? " nav-is-visible" : "")}>
                {!(props.isHome || props.breadcrumb === false) && <Breadcrumb title={props.title} />}
                {props.children}
            </main>
            {!isDesktop && <Navigation history={props.history}
                menuItems={menuItems}
                addLanguage={addLanguage}
                languages={languages}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                addMenuItem={addMenuItem}
                onClickMenuItem={onClickMenuItem}
                burgerMenuClicked={burgerMenuClicked} />}
            <footer className="footer">
                <p>
                    &copy; {new Date().getFullYear()} - {_layout.company_name}<br />
                    <a href="/PrivacyPolicy">{t('layout.footer.privacypolicy')}</a><br />
                    {_layout.footer.children.map((component, index) => {
                        return cf(component);
                    })}
                </p>
                <div>{t('layout.footer.poweredby')} <a target="_blank" href="https://vudu.tech/" title="Vudu Technologies Limited">Vudu Technologies Limited</a></div>
            </footer>
            <div className={"cd-overlay" + ((isDesktop && menuItemClicked(menuItems)) || burgerMenuClicked ? " is-visible" : "")}></div>
        </div>
    );
}

const Navigation = (props) => {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();

    const onClickButton = (e, url) => {
        e.preventDefault();
        navigate(url);
    }

    const buildOptions = () => {
        let languageOptions = [];

        for (var language in props.languages){
            languageOptions.push({ 
                key: props.languages[language],
                text: t(language),
                value: props.languages[language]
            });
        };

        return languageOptions;
    }

    useEffect(() => {
        _layout.menu.children.map((menuItem, index) => {
            if (menuItem.submenu) {
                props.addMenuItem(menuItem.title);
                menuItem.submenu.columns.map((column, index) => {
                    props.addMenuItem(column.title);
                })
            }
        })
        _layout.languages.map((language, index) => {
            props.addLanguage(language.title, language.code);
        })
    }, [])

    const getMenuItem = (menuItem) => {
        if (menuItem.submenu) {
            return (<li key={menuItem.key + "_1"} className="has-children">
                <a href="#"
                    className={props.menuItems[menuItem.title] ? " selected" : ""} onClick={e => props.onClickMenuItem(e, menuItem.title)}>{t(menuItem.title)}</a>
                <ul className={"cd-secondary-nav" + (!props.menuItems[menuItem.title] ? " is-hidden" : "")}>
                    <li key={menuItem.key + "_2"} className="go-back"><a onClick={e => props.onClickMenuItem(e, menuItem.title)}>{t(menuItem.submenu.back)}</a></li>
                    <li key={menuItem.key + "_3"} className="see-all"></li>
                    {menuItem.submenu.columns.map((column, index) => {
                        return (<li key={column.key} className="has-children">
                            <a href="#" onClick={e => props.onClickMenuItem(e, column.title)}>{t(column.title)}</a>
                            <ul className={(!props.menuItems[column.title] ? " is-hidden" : "")}>
                                <li key={column.key + "_1"} className="go-back"><a onClick={e => props.onClickMenuItem(e, column.title)}>{t(column.go_back)}</a></li>
                                {column.links.map((link, index2) => {
                                    let target = link.open_in_new_tab ? "_blank" : "_self";
                                    return (<li key={link.key}><a target={target} href={link.url}>{t(link.title)}</a></li>)
                                })}
                            </ul>
                        </li>)
                    })}
                </ul>
            </li>)
        } else if (menuItem.style === "button") {
            let clazz = menuItem.class ? `btn ${menuItem.class}` : 'btn';
            return (<li key={menuItem.key}><button className={clazz} onClick={(e) => { onClickButton(e, menuItem.url) }}>{t(menuItem.title)}</button></li>)
        }
        else {
            return (<li key={menuItem.key}><a href={menuItem.url}>{t(menuItem.title)}</a></li>)
        }
    }

    return (<nav className="cd-nav">

        <ul id="cd-primary-nav" className={"cd-primary-nav is-fixed" + (props.burgerMenuClicked ? " nav-is-visible" : "")}>
        <li><Dropdown
                button
                className='icon'
                value={props.selectedLanguage}
                floating
                labeled
                icon='world'
                options={buildOptions()}
                
                onChange={props.setSelectedLanguage}
            /></li>
            {_layout.menu.children.map((menuItem, index) => {
                return getMenuItem(menuItem);
            })}
        </ul>
    </nav>)
}

export default _LayoutView;
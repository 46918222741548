import * as React from 'react';

const Row = (props) => {
    let clazz = `row ${props.class}`
    return (
        <div className={clazz}>
            {props.children}
        </div>
    );
}

export default Row;
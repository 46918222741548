import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const Button = (props) => {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    let clazz = props.indent ? "btn list-btn" : "btn";

    const click = (e) => {

        if(props.external) {
            var win = window.open(props.url, '_blank');
            win.focus();
        } else {
            navigate(props.url);
        }
    }

    return (
        <button id={props.id} className={clazz} onClick={() => click()}>{t(props.title)}</button>
    );
}

export default Button;
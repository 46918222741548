{
    "title": "page.sales.title",
    "components": [{
        "key": "ktmvECt/CUOuKfpPCnedjA==",
        "type": "panel",
        "title": "page.sales.pharmacysales.title",
        "children": [{
            "key": "lC+ZLkjCDUiCmnbhxsrRiQ==",
            "type": "column",
            "width": 3,
            "children": [{
                "key": "NHfJZmb4W0qk1mI1h8RMQA==",
                "type": "image",
                "image": "pharmacysales.jpg"
            }]
        }, {
            "key": "nRimbqwJm06u35NCzi5McQ==",
            "type": "column",
            "width": 9,
            "children": [{
                    "key": "MUyFnCOQ0024waUVDq0+Vw==",
                    "type": "text",
                    "value": "page.sales.pharmacysales.p1"
                }, {
                    "key": "hBb4NZWNeEWMGv1FB661Ug==",
                    "type": "text",
                    "value": "page.sales.pharmacysales.p2"
                }, {
                    "key": "mqZZzboUpUint/NtJqnr4A==",
                    "type": "text",
                    "value": "page.sales.pharmacysales.p3"
            }]
        }]
    }]
}
{
    "title": "page.weightloss.title",
    "components": [
        {
            "type": "panel",
            "title": "page.weightloss.title",
            "children": [
                {
                    "type": "text",
                    "value": "Services suspended until further notice.",
                    "key": "BPbJNprGx0eOxG/V2Ni0Sw=="
                },
                // {
                //     "type": "text",
                //     "value": "page.weightloss.p1",
                //     "bold": true,
                //     "key": "HJ9KRVN2RjG2zScWLDM4Dg=="
                // },
                // {
                //     "type": "text",
                //     "value": "page.weightloss.p2",
                //     "key": "noSvkRQVQAqWuk4ZQ2YD4g=="
                // },
                // {
                //     "type": "text",
                //     "value": "page.weightloss.p3",
                //     "bold": true,
                //     "key": "jVWnapw1SOySU3NM7kINiQ=="
                // },
                // {
                //     "type": "text",
                //     "value": "page.weightloss.p4",
                //     "key": "gEgqsAkqS9eJ/Iv6NrBzAw=="
                // },
                // {
                //     "type": "text",
                //     "value": "page.weightloss.p5",
                //     "bold": true,
                //     "key": "vPD1HFclRVacY/wONnw/Lw=="
                // },
                // {
                //     "type": "text",
                //     "value": "page.weightloss.p6",
                //     "key": "DLRl7ccYRumnmcEe6LXeiQ=="
                // },
                // {
                //     "type": "text",
                //     "value": "page.weightloss.p7",
                //     "key": "DId5TOMfTcKanTurFHwPfQ=="
                // },
                // {
                //     "type": "text",
                //     "value": "page.weightloss.p8",
                //     "bold": true,
                //     "key": "kKnwKBh7Rq6RsmcOHe57iQ=="
                // },
                // {
                //     "type": "checklist",
                //     "id": "weightloss_steps",
                //     "items": [
                //         {
                //             "value": "page.weightloss.p8c1",
                //             "key": "5Gyvgx96Qcufv0Pub+Z1CA=="
                //         },
                //         {
                //             "value": "page.weightloss.p8c2",
                //             "key": "ktg8gGoPRReAsW9HtHkTBw=="
                //         },
                //         {
                //             "value": "page.weightloss.p8c3",
                //             "key": "n36lQ/3ER1iFEugZXXSrZw=="
                //         }
                //     ],
                //     "key": "7pPL1x1ZQCCnTpqCpVzjMQ=="
                // },
                // {
                //     "type": "text",
                //     "value": "page.weightloss.p9",
                //     "bold": true,
                //     "key": "invYtSzdQ8iIgjFP/3vP8Q=="
                // },
                // {
                //     "type": "text",
                //     "value": "page.weightloss.p10",
                //     "key": "p4rxVLX+RgWs5HqmanNwOg=="
                // },
                // {
                //     "type": "button",
                //     "id": "locate-store-button",
                //     "title": "page.weightloss.locate",
                //     "url": "/FindUs",
                //     "key": "O9cUQQsnRK+kzqcEsADY7g=="
                // },
                // {
                //     "type": "video",
                //     "url": "https://www.youtube.com/embed/H1mdJAc6-9w",
                //     "key": "s5hpsH+TSfO+V9fxjQnIWA=="
                // }
            ],
            "key": "cuHfiWrIRGy1YimDwYystQ=="
        }
    ],
    "key": "uEsQySxgQ6ivIAiXyDpXcg=="
}
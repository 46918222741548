import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import database from '../content/database';
import nextKey from './KeyGenerator';
import cf from './ComponentFactory'
import { setPageCache, useGlobalState } from '../api/state';

const Table = (props) => {
    const { t, i18n } = useTranslation();
    const [globalData] = useGlobalState('globalData') 
    const [loaded] = useGlobalState('loaded')
    const [pageCache] = useGlobalState('pageCache')
    let cache = {};

    useEffect(() => {
        if(loaded) {
            getData(props.source, props.data);
            setPageCache(pageCache);
        }
    }, [])
    
    const getData = (source, data) => {

        if(pageCache[data]) {
            cache = pageCache[data];
        } else {
            let rows = [];
            if (source === 'file') {
                rows = database[data].data;
            } else if (source === 'api' && globalData) {
                rows = globalData[data];
            }
    
            cache = rows && rows.map((row, index) => {
                return (<div key={row.key ?? row.id} className="row ols-row">
                    {props.virtual_columns && props.virtual_columns.map((col, index2) => {
                        return (<div key={row.key + '_' + index2} className="col-6 ols-row-cell">{col.children.map((v_child, index3) => {
                           let buffer = {};
                            if (row[v_child.value]) {
                                buffer = JSON.parse(JSON.stringify(v_child))
                                buffer.value = row[v_child.value];
                            }
                            return cf(buffer)
                        })}</div>)
                    })}
                    {!props.virtual_columns && Object.keys(row).map(function (cell, index2) {
                        return (cell != "key" && <div key={row.key + '_' + index2} className="col-4 ols-row-cell">
                            <div style={index2 === 0 ? props.cellStyle : props.cellStyleNoLeftPadding}>{row[cell]}</div>
                        </div>)
                    })}
                </div>)
            });

            pageCache[data] = cache;
        }

        return cache;
    }

    return ( 
        loaded && <div className="ols-table">
            <div className="row ols-row">
                {props.columns && props.columns.map((item, index) => {
                    return (<div key={nextKey(item)} className="col-4 ols-row-cell">
                        <div style={props.headingStyle}>{t(item)}</div>
                    </div>)
                })}
            </div>
            {getData(props.source, props.data)}
            {props.footer && <div className="row ols-row">
                <div className="col-12 ols-row-cell">
                    <div style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: 15 }}>{t(props.footer)}</div>
                </div>
            </div>}
        </div>
    );
}

export default Table;
import React, { useReducer, useEffect } from 'react';
import _LayoutView from './_LayoutView';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import cf from '../components/ComponentFactory';
import rest from '../api/rest'
import useAxios from 'axios-hooks'
import { Breadcrumb, Button } from 'semantic-ui-react';
import { setGlobalData, setLoaded, useGlobalState } from '../api/state';
import { AZURE_HOST_PREFIX } from '../api/api_config';
import "react-image-gallery/styles/css/image-gallery.css";

const reducer = (state, action) => {
    if(action.type == 'reset') {
        return initialState;
    }

    const result = { ...state };
    result[action.type] = action.value;
    return result;
}

const PageView = (props) => {
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [loaded] = useGlobalState('loaded')

    const [{ data, loading, error }, refetch] = useAxios(
        `https://${AZURE_HOST_PREFIX}-remedi-api-fa-pr.azurewebsites.net/api/ols`, { manual: !loaded }
    )

    useEffect(() => {
        if(!loaded) {
            refetch().then((data) => {
                setGlobalData(data.data);
                setLoaded(true);
            });
        }
    }, [])

    return (
        <React.StrictMode>
            <_LayoutView history={props.history} title={t(props.content.title)} breadcrumb={props.content.breadcrumb}>
                {props.content.components.map((item, index) => {
                    return cf(item)
                })}
            </_LayoutView>
        </React.StrictMode>
    );
}

export default PageView;
{
    "title": "page.vaccines.title",
    "components": [
        {
            "type": "panel",
            "title": "page.vaccines.vaccines.title",
            "children": [
                {
                    "type": "checklist",
                    "id": "free_vaccines",
                    "items": [
                        {
                            "value": "page.vaccines.vaccines.c1",
                            "key": "YZZCn76GQBancgJBZyCWlQ=="
                        },
                        {
                            "value": "page.vaccines.vaccines.c2",
                            "key": "F0q9QxaeRCSnSxUhPQvMmA=="
                        },
                        {
                            "value": "page.vaccines.vaccines.c3",
                            "key": "LRL4koolQi6ofyBZqJGgRA=="
                        },
                        {
                            "value": "page.vaccines.vaccines.c4",
                            "key": "oqv8XF+FQQW0zuE7QLUzWQ=="
                        }
                    ],
                    "key": "Mguj7SXgTMmxKa/l7u5DbA=="
                },
                {
                    "type": "text",
                    "class": "large-text",
                    "value": "page.vaccines.vaccines.p1",
                    "markdown": true,
                    "key": "/E/9JrOhQAWWU3xDx6WMXA=="
                }
            ],
            "key": "09+8yDWwR2C3wwD6VcdxSg=="
        },
        {
            "type": "panel",
            "title": "page.vaccines.privatevaccines.title",
            "children": [
                {
                    "type": "checklist",
                    "id": "free_vaccines",
                    "items": [
                        {
                            "value": "page.vaccines.privatevaccines.c1",
                            "key": "bfyUhdQDQke/O6K051M8tQ=="
                        },
                        {
                            "value": "page.vaccines.privatevaccines.c2",
                            "key": "KgZmx7x8QcmXVyaCV2dxJw=="
                        },
                        {
                            "value": "page.vaccines.privatevaccines.c3",
                            "key": "4r6R8T44RM2jiL9S88EFNQ=="
                        }
                    ],
                    "key": "PaHOSMWRSZypREugX2OFrw=="
                },
                {
                    "type": "button",
                    "id": "locate-store-button",
                    "title": "page.vaccines.privatevaccines.find",
                    "url": "/FindUs",
                    "indent": true,
                    "key": "F8n1g9xiQ8CP59hUaQhQdA=="
                }
            ],
            "key": "9OSDiTVeS868dPWkaLmNRg=="
        },
        {
            "type": "panel",
            "title": "page.vaccines.onsite.title",
            "children": [
                {
                    "type": "checklist",
                    "id": "free_vaccines",
                    "items": [
                        {
                            "value": "page.vaccines.onsite.c1",
                            "key": "c1TNLdZOSPiQiaMi1mlWiA=="
                        },
                        {
                            "value": "page.vaccines.onsite.c2",
                            "key": "OHCe1VkySDeSMpwgs8TAGA=="
                        },
                        {
                            "value": "page.vaccines.onsite.c3",
                            "key": "bSlSh2YOQye2hFca+GGWGA=="
                        },
                        {
                            "value": "page.vaccines.onsite.c4",
                            "key": "2eKPMao9Qki8tLJVuDiUiA=="
                        },
                        {
                            "value": "page.vaccines.onsite.c5",
                            "key": "Nqw4791WR4+ojSdiNEPxZQ=="
                        },
                        {
                            "value": "page.vaccines.onsite.c6",
                            "key": "BpkBzsM1RBqhRmOIBgHypQ=="
                        }
                    ],
                    "key": "Dg1u33oqTxyWNSi8PzMbIQ=="
                },
                {
                    "type": "quote",
                    "id": "vaccine_quotes",
                    "children": [
                        {
                            "reviewer": "page.vaccines.review1.reviewer",
                            "quote": "page.vaccines.review1.quote",
                            "key": "FXCgyAwuRc66C9NmE/IprQ=="
                        },
                        {
                            "reviewer": "page.vaccines.review2.reviewer",
                            "quote": "page.vaccines.review2.quote",
                            "key": "lMVl9vaYQpyGGmNDuBY1AA=="
                        },
                        {
                            "reviewer": "page.vaccines.review3.reviewer",
                            "quote": "page.vaccines.review3.quote",
                            "key": "K+GZqcs3TAqqVZ2Mz6IYBQ=="
                        }
                    ],
                    "key": "4HxQXjOJRhmQYo/8WGXRQw=="
                }
            ],
            "key": "1u7bVcwlRjK/o2yA2TNXqA=="
        }
    ],
    "key": "KmVMNDW7SmO859BR2BcYag=="
}
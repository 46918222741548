{
    "title": "page.commonailments.title",
    "components": [{
        "key": "9rh0YhY9k0my1QGScbI6Ew==",
        "type": "panel",
        "title": "page.commonailments.indyprescriptandcomailment.title",
        "children": [{
            "key": "4L4HybpRgU6uar7OEXnnTA==",
            "type": "text",
            "value": "page.commonailments.indyprescriptandcomailment.p1"
        }, {
            "key": "roL7ut9in0KY241gw8dKow==",
            "type": "text",
            "value": "page.commonailments.indyprescriptandcomailment.p2"
        }]
    }, {
        "key": "dpLGDkRvAUGolD3IKEfAvQ==",
        "type": "panel",
        "title": "page.commonailments.uti.title",
        "children": [{
            "key": "0QHwZLoML0O4dqQ8whmE+g==",
            "type": "text",
            "value": "page.commonailments.uti.p1"
        }, {
            "key": "TSqp4u+KxEqFOwChDvuftg==",
            "type": "text",
            "value": "page.commonailments.uti.p2"
        }, {
            "key": "CtkQX0C5y0W7o85/QWfvIA==",
            "type": "text",
            "value": "page.commonailments.uti.p3",
            "markdown": true
        }, {
            "key": "J99S0cGHJkeuEFCUWE3dBg==",
            "type": "row",
            "class": "earwaximage-row-top-margin",
            "children": [{
                    "key": "A2HlLWMhu0ChgI+qpfoW7w==",
                    "type": "column",
                    "width": 3,
                    "children": [{
                        "key": "qRnFmfeAe0uBjX0uWlBSAw==",
                        "type": "image",
                        "image": "uti.png"
                    }]
                }]
        }]
    }, {
        "key": "sEdjIZ0yaE25LxRO2C7KMw==",
        "type": "panel",
        "title": "page.commonailments.earissues.title",
        "children": [{
            "key": "/onC9TLM0k+48S7KqLzeuA==",
            "type": "text",
            "value": "page.commonailments.earissues.p1"
        }, {
            "key": "izjhM6BqP0SimMvRFkoidQ==",
            "type": "text",
            "value": "page.commonailments.earissues.p2",
            "markdown": true
        }]
    }, {
        "key": "iqZCx6VdRESL+BR5s8Pizg==",
        "type": "panel",
        "title": "page.commonailments.oralcontraceptives.title",
        "children": [{
            "key": "M6v0DMkkqkWuOKRbrRCkJw==",
            "type": "text",
            "value": "page.commonailments.oralcontraceptives.p1"
        }, {
            "key": "9Ye5rgugYkSPZyH+a1pvFA==",
            "type": "text",
            "value": "page.commonailments.oralcontraceptives.p2",
            "markdown": true
        }, {
            "key": "fGy6V11nzU+Gfl8wtMzVgQ==",
            "type": "text",
            "value": "page.commonailments.oralcontraceptives.p3"
        }]
    }, {
        "key": "JJPFLDS/QUWMFZUj35YI4w==",
        "type": "panel",
        "children": [{
            "key": "RqlJw1LzVkm6u5sZKDBsWQ==",
            "type": "text",
            "value": "page.commonailments.ailmentslist.p1"
        }, {
            "key": "hJLCqjQlmkGq+gifZJ4kjg==",
            "type": "text",
            "value": "page.commonailments.ailmentslist.p2"
        }, {
            "key": "l3vDf4t/9kGaZ90rAALEAg==",
            "type": "row",
            "class": "earwaximage-row-top-margin",
            "children": [{
                    "key": "8aEDmbzLmUqHVIL0nH2P1g==",
                    "type": "column",
                    "width": 6,
                    "children": [{
                        "key": "AhRpyHBIxE+CCmJHCPrVnw==",
                        "type": "image",
                        "image": "common_ailments.png"
                    }]
                }]
        }]
    }]
}
{
    "title": "page.contact.title",
    "components": [{
        "key": "4djDAwPF5UW8MawnOtvqpw==",
        "type": "row",
        "children": [{
            "key": "WNl9CGUOskKc4NTSWae1xA==",
            "type": "column",
            "width": 6,
            "children": [{
                "key": "OzPNNL2+d0OJCAFwQ3DEuw==",
                "type": "panel",
                "title": "page.contact.form.title",
                "disable_columns": true,
                "children": [{
                    "key": "myP3Zi5hXkOtB8vK9ZR8WA==",
                    "type": "form",
                    "submit": "page.contact.form.submit",
                    "success": {
                        "title": "page.contact.successresponse.title",
                        "description": "page.contact.successresponse.p1",
                        "return_home": "page.contact.successresponse.returntohome"
                    },
                    "fields": [{
                        "key": "W4AJS3WYl0aeUpFN7VBnaw==",
                        "type": "text",
                        "title": "page.contact.form.name",
                        "tag": "name"
                    }, {
                        "key": "BMdQhatLRkuds0bLJ1qdNw==",
                        "type": "email",
                        "title": "page.contact.form.email",
                        "tag": "email"
                    }, {
                        "key": "r+YYt4NaxUaSDbmw5KpSxw==",
                        "type": "text",
                        "title": "page.contact.form.subject",
                        "tag": "subject"
                    }, {
                        "key": "XKu4q17mJEyuAv+NCLviHw==",
                        "type": "text_area",
                        "title": "page.contact.form.message",
                        "tag": "message"
                    }]
                }, {
                    "key": "IkAoFLUlvk6POfoSDWFklw==",
                    "type": "text",
                    "value": "page.contact.form.footnote"
                }, {
                    "key": "8oggwV4q2ki9tk0ShDE9/g==",
                    "type": "text",
                    "bold": true,
                    "value": "page.contact.form.footnote1"
                }, {
                    "key": "zJ6O2JvZe0u+KY7+McWCNA==",
                    "type": "text",
                    "value": "page.contact.form.footnote2"
                }]
            }]
        }, {
            "key": "4TUOeX+yp0qdC51+Wxb9mQ==",
            "type": "column",
            "width": 6,
            "children": [{
                "key": "xdBAtg2UzkiYcbHql5HN6Q==",
                "type": "panel",
                "title": "page.contact.contactinformation.title",
                "disable_columns": true,
                "children": [{
                    "key": "1wilzjlBWUK9S+KzwynysA==",
                    "type": "text",
                    "value": "Robert Cousins"
                }, {
                    "key": "9rR2+gbzmkmu96RMMJ271A==",
                    "type": "text",
                    "value": "Insync Healthcare Pharmacy"
                }, {
                    "key": "SHeKzUAzRkWw9E0e4b+9fQ==",
                    "type": "text",
                    "value": "67 Thornhill Road"
                }, {
                    "key": "b3pbIlDeDU2m2MOzubdXlw==",
                    "type": "text",
                    "value": "Llanishen"
                }, {
                    "key": "YKvwf5f8L0OteKP8ZOwm2Q==",
                    "type": "text",
                    "value": "CF14 6PE"
                }, {
                    "key": "8RDZ1+MddUS8fjHG3DS31w==",
                    "type": "text",
                    "value": "page.contact.contactinformation.phone"
                }, {
                    "key": "zEV1lvcsdEqKbEYHHBhGUQ==",
                    "type": "text",
                    "value": "page.contact.contactinformation.freephone"
                }, {
                    "key": "XVLMFEFUAkuhPhb05C2r4A==",
                    "type": "text",
                    "value": "page.contact.contactinformation.fax"
                }, {
                    "key": "aMmX8gEm6UqEcsMcKCWmiA==",
                    "type": "text",
                    "value": "page.contact.contactinformation.email",
                    "markdown": true
                }]
            }]
        }]
    }]
}
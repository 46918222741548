import * as React from 'react';

const Column = (props) => {
    var clazz = `col-${props.width}`;
    clazz = props.class ? `${clazz} ${props.class}` : clazz; 
    return (
        <div className={clazz}>
            {props.children}
        </div>
    );
}

export default Column;
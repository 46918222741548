import React, { useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Row from './Row'
import Column from './Column';
import cf from './ComponentFactory'

const initialState = {
    selectedTabIndex: 0
}

const reducer = (state, action) => {
    if (action.type == 'reset') {
        return initialState;
    }

    const result = { ...state };
    result[action.type] = action.value;
    return result;
}

const Tabs = (props) => {
    const { t, i18n } = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { selectedTabIndex } = state;

    const changeTabIndex = (index) => {
        dispatch({ type: 'selectedTabIndex', value: index });
    }

    const changeTab = (e) => {
        dispatch({ type: 'selectedTabIndex', value: e.target.value });
    }

    // useEffect(() => {
    //     if (props.location && props.location.search) {
    //         const values = queryString.parse(this.props.location.search)

    //         if (values.selected) { changeTabIndex(values.selected - 1) }
    //     }
    // }, [])

    return (
        <Row>
            <Column width={12}>
                <div className="rt-tabwrap">
                    {props.tabs.map((tab, index) => {
                        let id = `tab${index + 1}`;
                        let clazz = `tab tab${index + 1}`;
                        return (<React.Fragment key={tab.key + "_tab"}><input className={clazz} type="radio" value={index} checked={state.selectedTabIndex == index} onChange={(e) => changeTab(e)} id={id} name="tabs" />
                            <label className={clazz} htmlFor={id} >{t(tab.tab)}</label></React.Fragment >)
                    })}
                    <div className="tabs">
                        {props.tabs.map((tab, index) => {
                            let clazz = `content content${index + 1}`;
                            return (<div key={tab.key} className={clazz}>
                                <div className="content-inner">
                                    {tab.children.map((component, index) => {
                                        return cf(component);
                                    })}
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
            </Column>
        </Row>
    );
}

export default Tabs;
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const Quote = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <div className="row">
            {props.children.map((item, index) => {
                return (<div key={item.key} className="col-4">
                    <figure className="snip1533">
                        <figcaption>
                            <blockquote>
                                <p>{t(item.quote)}</p>
                            </blockquote>
                            <h3>{t(item.reviewer)}</h3>
                        </figcaption>
                    </figure>
                </div>)
            })}
        </div>
    );
}

export default Quote;
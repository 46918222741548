import * as React from 'react';
import { useTranslation } from 'react-i18next';

const Heading = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <h1>{t(props.value)}</h1>
    )
}

export default Heading;
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// const resources = {
//   en: {
//     translation: {
//       'Repeat Prescriptions': 'Repeat Prescriptions'
//     }
//   },
//   cy: {
//     translation: {
//       'Repeat Prescriptions': ''
//     }
//   }
// };

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en", 
    fallBackLng: 'en',
    backend: {
      loadPath: 'locales/{{lng}}/translation.json'
    },

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    react: { 
      useSuspense: false //   <---- this will do the magic
    }
  });

  export default i18n;
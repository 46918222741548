import * as React from 'react';
import { useTranslation } from 'react-i18next';

const ObjectLink = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <a href={props.url}>
            {props.children}
        </a>);
}

export default ObjectLink;